import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';
import VueAxios from 'vue-axios';
//import { reject } from 'core-js/fn/promise';

import assets from './modules/assets';
import buckets from './modules/buckets'
import groups from './modules/groups';
import positions from './modules/positions';
import user from './modules/user.js';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
    modules: {
        assets,
        buckets,
        groups,
        positions,
        user,
    },
    state: {
        baseURL: {
            production: 'https://warren-api.ciops.ro',
            development: 'http://localhost:5050',
        },
        env: process.env.NODE_ENV,

        syncStatus: 'not_synced',
        syncTook: 'N/A',
        processingTook: 'N/A',
        dbUsed: null,
        pricingTime: null,
        todaysActivity: null,

        intervalCheckAPI: null, // timer for refreshing data
        canSync: true,

        positions_last_sync: null,
        userStats: {},
        userData: {},
    },
    getters: {
        getTodaysActivityComputed(state) {
            let returnedData = {
                buys: 0,
                reinvested: 0,
                exit: 0,
                error: 0,
                current: state.todaysActivity
            }
            let closedReinvested = 0

            if (state.todaysActivity && 'open' in state.todaysActivity) {
                let openOptions = Object.keys(state.todaysActivity.open)
                for (let i=0; i < openOptions.length; i++) {
                    if (openOptions[i].includes('reinvested')) {
                        returnedData['reinvested'] += state.todaysActivity.open[openOptions[i]];
                    }

                    if (openOptions[i].includes('buy')) {
                        returnedData['buys'] += state.todaysActivity.open[openOptions[i]];
                    }
                }
            }

            if (state.todaysActivity && 'closed' in state.todaysActivity) {
                let closedOptions = Object.keys(state.todaysActivity.closed)
                for (let i=0; i < closedOptions.length; i++) {
                    if (closedOptions[i].includes('reinvested')) {
                        closedReinvested += state.todaysActivity.closed[closedOptions[i]];
                    }
                    if (closedOptions[i].includes('exit')) {
                        returnedData.exit += state.todaysActivity.closed[closedOptions[i]];
                    }
                }
            }
            
            if (closedReinvested >= returnedData['reinvested']) {
                returnedData.error += (closedReinvested - returnedData['reinvested']);
            }

            return returnedData;
        },
        lastPositionsSync(state) {
            let now = new Date;
            return state.positions_last_sync || now;
        },
        getEnv(state) {
            return state.env;
        },
        isProd(state) {
            if (state.env == 'prod') return true;
            else return false;
        },
        actionableToSell(state) {
            if (!state.positions.actionablePositions.length) return []
            
            var positionList = [];
            for (var i = 0; i < state.positions.actionablePositions.length; i++) {
                let position = state.positions.actionablePositions[i];
                
                if (position.indicator >= 8) {
                    positionList.push(position);
                }
            }
            
            return positionList
        },
        actionableToBuy(state) {
            if (!state.positions.actionablePositions.length) return []
            
            var positionList = []
            for (var i = 0; i < state.positions.actionablePositions.length; i++) {
                let position = state.positions.actionablePositions[i];
                let asset = state.assets.assetMap.get(position.ticker)
                
                
                if (position.indicator <= -2) {
                    if (asset.acts_like_conviction == 'high') {
                        if (position.indicator <= -2) positionList.push(position)

                    } else if (asset.acts_like_conviction == 'low') {
                        if (position.indicator <= -10) positionList.push(position)

                    } else if ((asset.acts_like_conviction != 'snoozed') && (position.indicator <= -5)) {
                        positionList.push(position);
                    }
                }
            }
            
            return positionList
        }
    },
    mutations: {
        updateTodaysActivity(state, todaysActivity) {
            state.todaysActivity = todaysActivity;
        },
        updateSyncTime(state, syncTook) {
            state.syncTook = syncTook.toFixed(2);
        },
        updateProcessingTime(state, processingTook) {
            state.processingTook = processingTook.toFixed(2);
        },
        updatePricingTime(state, pricingTime) {
            state.pricingTime = pricingTime + 'Z';
        },
        updateDbUsed(state, dbUsed) {
            state.dbUsed = dbUsed;
        },
        syncStarted(state) {
            state.syncStatus = 'syncing';
        },
        syncGetActionable(state) {
            state.syncStatus = 'getActionable';
        },
        syncProcessing(state) {
            state.syncStatus = 'processing';
        },
        syncEnded(state) {
            state.syncStatus = 'synced';
        },
        updateLastPositionsSync(state) {
            state.positions.lastSync = new Date;
        },
        setUserData(state, userData) {
            state.userData = userData;
        },
        setUserStats(state, userStats) {
            state.userStats = userStats;
        },
        pauseSync(state) {
            state.canSync = false;
        },
        resumeSync(state) {
            state.canSync = true;
        },
        cancelSyncTimer(state) {
            clearInterval(state.intervalCheckAPI);
        }
    },
    actions: {
        addNewPositions(context, position) {    
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        context.state.baseURL[context.state.env] + '/api/add_position',
                        {
                            position: position,
                        }
                    )
                    .then((response) => {
                        let positions = response.data;
                        for ( let i = 0; i < positions.length; i++) {
                            context.commit('newlyAddedPosition', positions[i]);
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        reinvestPosition(context, pid) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        context.state.baseURL[context.state.env] + '/api/reinvest_position',
                        {
                            pid: pid,
                        }
                    )
                    .then((response) => {
                        let data = response.data;
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        retrieveLastOpenPositions(context) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['X-Access-Token'] = context.state.user.accessToken;
                axios
                    .get(context.state.baseURL[context.state.env] + '/api/positions/last_open')
                    .then((response) => {
                        let positions = response.data.positions;
                        
                        var i;  
                        // adding open positions to front-end DB
                        for (i = 0; i < positions.length; i++) {
                            context.commit('newlyAddedPosition', positions[i]);
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                        if (error.request.status == 401) {
                            localStorage.removeItem('access_token');
                            context.commit('destroyAccessToken');
                        }
                        reject(error);
                    });
            });
        },
        retrieveToken(context, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        context.state.baseURL[context.state.env] + '/api/login',
                        {},
                        {
                            auth: {
                                username: credentials.username,
                                password: credentials.password,
                            },
                        }
                    )
                    .then((response) => {
                        let token = response.data.token;
                        localStorage.setItem('access_token', token);
                        context.commit('setAccessToken', token);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        destroyToken(context) {
            if (context.getters.loggedIn) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(context.state.baseURL[context.state.env] + '/api/logout')
                        .then((response) => {
                            localStorage.removeItem('access_token');
                            context.commit('destroyAccessToken');
                            resolve(response);
                        })
                        .catch((error) => {
                            localStorage.removeItem('access_token');
                            context.commit('destroyAccessToken');
                            reject(error);
                        });
                });
            }
        },
        disableSyncTimer(context) {
            context.commit('cancelSyncTimer')
        },
        syncData(context) {
            if (context.state.canSync) {
                context.commit('syncStarted');
                context.commit('updateRequestedSync');
                context.commit('pauseSync');
                
                context.dispatch('requestSync').then(() => {
                    context.commit('syncGetActionable');

                    context.dispatch('refreshData').then(() => {
                        console.log('placeholder')
                    });

                    setInterval(() => {
                        context.commit('resumeSync');
                    }, 5000); // 5 sec cooldown to resync the API with external data

                    clearInterval(context.state.intervalCheckAPI);
                    context.state.intervalCheckAPI = setInterval(() => {
                        context.dispatch('syncData');
                    }, 300000) // refresh every 300 sec / 5 mins
                })
            } else {
                context.dispatch('refreshData');
            }
        },
        refreshData(context) {
            return new Promise((resolve, reject) => {
                context.commit('syncProcessing');
                context.dispatch('loadActionableData').then(() => {
                    context.dispatch('loadUserStats');
                    context.commit('syncEnded');
                    resolve()
                }).catch((error) => {
                    context.commit('resumeSync');
                    reject(error); 
                });
                
                resolve(); 
            });  
        },
        requestSync(context) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['X-Access-Token'] = context.state.user.accessToken;
                axios
                    .get(context.state.baseURL[context.state.env] + '/api/sync_data')
                    .then((response) => {
                        // assume all is good in the hood
                        // TODO: don't assume
                        context.commit('updateSyncTime', response.data.sync_took);
                        context.commit('updatePricingTime', response.data.pricing_time);
                        context.commit('updateDbUsed', response.data.db_used)
                        resolve(response.data);
                    })
                    .catch((error) => {
                        // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                        if (error.request.status == 401) {
                            localStorage.removeItem('access_token');
                            context.commit('destroyAccessToken');
                        }
                        reject(error);
                    });
            });
        },
        loadActionableData(context) {
            return new Promise((resolve, reject) => {
                context.commit('syncProcessing');
                axios.defaults.headers.common['X-Access-Token'] = context.state.user.accessToken;
                axios
                    .get(context.state.baseURL[context.state.env] + '/api/positions/actionable')
                    .then((response) => {
                        context.commit('updateProcessingTime', response.data.processing_took);

                        let todaysActivity = response.data.todays_activity;
                        let positions = response.data.positions;
                        let assetList = response.data.assetList; // V2
                        let userData = response.data.user;
                        var groupList = [];
                                       
                        context.dispatch('updateAssets', assetList);
                        context.dispatch('addGroupList', groupList);
                        context.commit('sortAssetSymbolList');

                        // V1
                        context.commit('updateLastPositionsSync');
                        context.commit('setUserData', userData);

                        // v2
                        context.dispatch('ingestActionablePositions', positions);

                        // latest
                        context.commit('updateTodaysActivity', todaysActivity)

                        let currentTimestamp = new Date();
                        console.log(currentTimestamp.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }), 'refreshed actionable Positions')

                        resolve(response.data);
                    })
                    .catch((error) => {
                        console.log(error)
                        // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                        if (error.request.status == 401) {
                            localStorage.removeItem('access_token');
                            context.commit('destroyAccessToken');
                        }
                        reject(error);
                    });
            });
        }, 
        loadUserStats(context) {
            axios.defaults.headers.common['X-Access-Token'] = context.state.user.accessToken;

            axios
                .get(context.state.baseURL[context.state.env] + '/api/user/stats')
                .then((data) => {
                    let userStats = data.data;
                    context.commit('setUserStats', userStats);
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        context.commit('destroyAccessToken');
                    }
                });
        },
    },
});