<template>
    <div class="app-navbar-content">
        <div>
            <b-row class="pb-2" align-v="center" no-gutters>
                <b-col class="">
                    <router-link :to="{ name: 'stats' }" class="no-decoration">
                        <div>
                            <span v-if="activity.buys" class="text-danger"><b-icon-box-arrow-in-down /> <strong>{{ activity.buys }} </strong> </span> 
                            <span v-else class="text-secondary"><b-icon-box-arrow-in-down /> <strong>{{ activity.buys }} </strong> </span> 
                            &nbsp;
                            <span v-if="activity.reinvested " class="text-warning"><b-icon-arrow-down-up /> <strong>{{ activity.reinvested }} </strong> </span> 
                            <span v-else class="text-secondary"><b-icon-arrow-down-up /> <strong>{{ activity.reinvested }} </strong> </span> 
                            &nbsp;
                            <span v-if="activity.exit" class="text-success"><b-icon-box-arrow-up /> <strong>{{ activity.exit }} </strong> </span>
                            <span v-else class="text-secondary"><b-icon-box-arrow-up /> <strong>{{ activity.exit }} </strong> </span>

                            <span v-if="activity.error" class="text-danger"><b-icon-exclamation-circle-fill /> <strong>{{ activity.error }}</strong></span>
                        </div>
                    </router-link>
                </b-col>
                <b-col class="text-right sync-data">
                    <b-icon-clock class="text-secondary" /> <span class="mr-1">{{ pricingTime }}</span>
                    <b-icon-arrow-repeat class="text-secondary" /> <span class="text-secondary">{{ syncTook }} / {{ processingTook }}</span>
                </b-col>
            </b-row>
        </div>

        <b-row no-gutters>
            <!-- <b-col>
                <b-button-toolbar>
                    <b-button-group>
                         
                    </b-button-group>
                </b-button-toolbar>

                
            </b-col> -->
            <b-col class="text-center">
                <b-button-group class="mr-3">
                    <b-button :to="{ name: 'buckets' }"><b-icon-terminal /></b-button>
                    <b-button :to="{ name: 'assets', params: { market: 'crypto' } }">crypto</b-button>
                    <b-button :to="{ name: 'assets', params: { market: 'stock' } }">stocks</b-button>
                </b-button-group>
                <b-button-group>
                    <b-button :to="{ name: 'dashboard' }" class="font-weight-bold">actions</b-button>
                    <b-button @click.prevent="refreshData"><b-icon icon="arrow-clockwise"></b-icon></b-button>
                    <!--<b-button @click.prevent="suggestBuy"><b-icon icon="plus-circle"></b-icon></b-button>-->
                    
                </b-button-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import formatter from '../mixins/formatter';

export default {
    name: 'AppNavbar',
    props: ['assetsViewed', 'todaysActivity'],
    mixins: [formatter],
    data() { 
        return {

        }
    },
    created() {

    },
    methods: {
        refreshData() {
            this.$store.dispatch('syncData');
        },
        suggestBuy() {
            this.$emit('suggest-buy');
        },
    },
    computed: {
        activity() {
            return this.todaysActivity;
        },
        syncTook() {
            return this.$store.state.syncTook
        },
        processingTook() {
            return this.$store.state.processingTook
        },
        pricingTime() {
            let pricingTime = this.$store.state.pricingTime
            
            if (pricingTime) {
                return this.formatTimeFromISO(pricingTime).split(' ').join('');

            } 
            else return 'N/A'
            
        }
    }
}
</script>

<style scoped>
.app-navbar-content {
    background-color: #343a40;
    opacity: 0.9;
    filter: alpha(opacity=80);
    border-radius: 10px;
    padding: 0px;
}
.app-navbar-content .btn:focus {
    box-shadow: none;
}

.app-navbar-content ul.last-viewed {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.app-navbar-content ul.last-viewed li {
    display: inline;
    margin: 0px;
    padding: 0px;
}
.faded {
    opacity: 0.7;
    filter: alpha(opacity=70);
}
.no-decoration, .no-decoration:hover {
    text-decoration: none;
}
</style>