<template>
    <div v-if="userStats.stocks">
        <h1 class="mx-3 mt-3">Stats</h1>

        <rounded-container variant="dark">
            <template v-slot:title>Position dynamic</template>

            <buys-closed-chart 
                v-if="gotStatsData" 
                :chart-data="barChartData" 
                :styles="{ position: 'relative', padding: '10px' }"
                />
            
            <b-container class="my-3 text-center">
                Average position lifetime is <code>{{ averagePositionOpenDays }}</code> days
            </b-container>
        </rounded-container>

        <rounded-container variant="dark">
            <template v-slot:title>
                Locked: <strong>${{ Math.round((userStats.stocks.value_open_positions + userStats.crypto.value_open_positions) / 1000) }}K</strong>.
                YTD closed: <strong>${{ Math.round((userStats.stocks.ytd_closed_value + userStats.crypto.ytd_closed_value) / 1000) }}K</strong>.
            </template>

            <b-container class="stats-container p-3">
                <b-row>
                    <b-col cols=4>
                        Value locked
                    </b-col>
                    <b-col>
                        <span class="skin_in_the_game">
                            ${{ userStats.stocks.value_open_positions }} (S)
                        </span>
                        <br />
                        <small>${{ userStats.stocks.value_main_positions }} in main</small>
                    </b-col>
                    <b-col>
                        <span class="skin_in_the_game">
                            ${{ userStats.crypto.value_open_positions }} (C)    
                        </span>
                    </b-col>
                </b-row>

                <b-row class="pt-3">
                    <b-col cols=4>
                        Positions
                    </b-col>
                    <b-col>
                        {{ userStats.stocks.no_open_positions }} /
                        {{
                            userStats.stocks.no_open_positions +
                                userStats.stocks.no_closed_positions
                        }}
                        <small>
                        ({{
                            Math.round(
                                (userStats.stocks.no_open_positions /
                                    (userStats.stocks.no_closed_positions +
                                        userStats.stocks.no_open_positions)) *
                                    100
                            )
                        }}%)
                        </small>
                    </b-col>
                    <b-col>
                        {{ userStats.crypto.no_open_positions }} /
                        {{
                            userStats.crypto.no_open_positions +
                                userStats.crypto.no_closed_positions
                        }}
                        <small>
                        ({{
                            Math.round(
                                (userStats.crypto.no_open_positions /
                                    (userStats.crypto.no_closed_positions +
                                        userStats.crypto.no_open_positions)) *
                                    100
                            )
                        }}%)
                        </small>
                    </b-col>
                </b-row>

                <b-row class="pt-3">
                    <b-col cols=4>
                        YTD closed
                    </b-col>
                    <b-col>
                        ${{ userStats.stocks.ytd_closed_value }}<br />
                        {{ userStats.stocks.ytd_closed_positions }} pos.
                    </b-col>
                    <b-col>
                        ${{ userStats.crypto.ytd_closed_value }}<br />
                        {{ userStats.crypto.ytd_closed_positions }} pos.
                    </b-col>
                </b-row>
                
                <b-row class="pt-5 text-center">
                    <b-col>
                        <b-button :to="{ name: 'stalePositions' }" pill variant="primary" class="mr-2">Stale Positions</b-button>
                        <b-button :to="{ name: 'portfolio' }" pill variant="primary">Portfolio overview</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </rounded-container>

        <rounded-container variant="dark" v-if="assetsToUpdate.length">
            <template v-slot:title>
                Assets that require a balance update
            </template>

            <b-container class="stats-container p-3">
                <b-button 
                    v-for="asset in assetsToUpdate" 
                    :key="asset.public_id"
                    :to="{ name: 'asset', params: { symbol: asset.symbol } }"
                    class="mr-2 mb-2"
                    variant="outline-light"
                >
                    {{ asset.symbol }}
                </b-button>
            </b-container>
        </rounded-container>
        
    </div>
</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';
import BuysClosedChart from '../components/stats/BuysClosedChart.vue';
import RoundedContainer from '../components/style/RoundedContainer.vue';

export default {
  components: { BuysClosedChart, RoundedContainer },
    name: 'Stats',

    data() {
        return {
            barChartData: {},
            gotStatsData: false,
            averagePositionOpenDays: 0,
            assetPublicIdsToUpdate: []
        }
    },
    methods: {

    },
    mounted() {
        this.gotStatsData = false;

        axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
        axios
            .get(this.$store.state.baseURL[this.$store.state.env] + '/api/system_status')
            .then((response) => {
                this.averagePositionOpenDays = response.data.average_days_open;

                if (response.data.assets_to_update?.length) {
                    this.assetPublicIdsToUpdate = response.data.assets_to_update;
                }

                // process data for chart
                let labels = [];
                let buyData = [];
                let closedData = [];

                const buys = response.data.no_buys_30d;
                for (const index in buys) {
                    labels.push(index);
                    buyData.push(buys[index]);
                }

                const closed = response.data.no_closed_30d;
                for (const index in closed) {
                    closedData.push(closed[index]);
                }

                this.barChartData = {
                    labels: labels.reverse(),
                    datasets: [
                        {
                            label: 'Buys',
                            backgroundColor: '#eb6534',
                            data: buyData.reverse(),
                        },
                        {
                            label: 'Closed',
                            backgroundColor: '#72cba0',
                            data: closedData.reverse(),
                        }
                    ]
                }

                this.gotStatsData = true; 
            })
            .catch((error) => {
                // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                if (error.request.status == 401) {
                    localStorage.removeItem('access_token');
                    this.$store.context.commit('destroyAccessToken');
                }
            });
    },
    computed: {
        ...mapState(['userStats']),
        assetsToUpdate() {
            const conviction100k = [];
            const convictionHigh = [];
            const convictionRegular = [];
            const convictionLow = [];
            const convictionSnoozed = [];
            const convictionOther = [];

            for (const eachPublicId of this.assetPublicIdsToUpdate) {
                const eachAsset = this.$store.getters.getAssetByPublicId(eachPublicId);

                if (eachAsset.conviction === '100k') {
                    conviction100k.push(eachAsset);
                } else if (eachAsset.conviction === 'high') {
                    convictionHigh.push(eachAsset);
                } else if (eachAsset.conviction === null) {
                    convictionRegular.push(eachAsset);
                } else if (eachAsset.conviction === 'low') {
                    convictionLow.push(eachAsset);
                } else if (eachAsset.conviction === 'snoozed') {
                    convictionSnoozed.push(eachAsset);
                } else {
                    convictionOther.push(eachAsset)
                }
            }
            return [
                ...conviction100k, 
                ...convictionHigh, 
                ...convictionRegular, 
                ...convictionLow, 
                ...convictionSnoozed
            ];
        }
    },
};
</script>

<style scoped>
.stats-container h3 {
    margin-top: 15px;
}
</style>
