<template>
    <div>
        <asset-navbar :filterMarket="filterMarket">
            <template v-slot:title>Groups</template>
            <template v-slot:subTitle>
            </template>
        </asset-navbar>

        <group-list 
            v-if="filterMarket && gotData" 
            :groupList="groups.filter(group => getAssetBySymbol(group.asset_symbol).market == filterMarket).sort((a, b) => (Math.abs(a.stage_change_time) < Math.abs(b.stage_change_time)) ? 1 : -1)" 
            /> 

        <div v-if="!gotData" class="p-3 text-center">Loading...</div>

        <div v-if="gotData && !groups.filter(group => getAssetBySymbol(group.asset_symbol).market == filterMarket).length" class="p-3 text-center">No long buys found</div>
    </div>
</template>

<script>
import axios from 'axios';

//import { mapGetters } from 'vuex';
import GroupList from '../components/GroupList.vue';
import AssetNavbar from '../components/AssetNavbar.vue';

export default {
    name: 'Groups',
    components: {
        GroupList,
        AssetNavbar
    },
    props: ['market', 'assets'],
    data() {
        return {
            groups: [],
            filterMarket: '',
            gotData: false,
        }
    },
    created() {
        this.fetchGroups()
        this.filterMarket = this.market;
    },
    methods: {
        getAssetBySymbol(symbol) {
            return this.$store.getters.getAssetBySymbol(symbol)
        },
        fetchGroups() {
            this.gotData = false;

            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/groups')
                .then((response) => {
                    this.groups = response.data.groups
                    this.gotData = true;

                    // TO DO: process response.data.assets
                    /*
                    this.actionsList = []; // reset the local list, new list is here
                    let actions = response.data;
                    var i;                  
                    for (i = 0; i < actions.length; i++) {
                        if (actions[i].type == 'boost') {
                            actions[i].key = "boost:".concat(actions[i].asset_symbol)
                        }
                        this.actionsList.push(actions[i])
                    }
                    */
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
    },
    watch: {
        $route(to) {
            // (to, from)
            // force content refresh when switching props
            this.filterMarket = to.params.market; 
        }
    }
}
</script>
