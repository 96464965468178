const state = () => ({
    positionMap: new Map(),
    lastOpenPositions: [],
    actionablePositions: [],
    requestedSync: null,
    lastSync: null,

    openToday: null,
    closedToday: null,
})

const getters = {
    getLastSyncReadable(state) {
        var referenceTime = null;
        var label = '';
        if (state.requestedSync && state.lastSync && (state.lastSync > state.requestedSync)) {
            referenceTime = state.lastSync;
            label = "last sync at ";
        } else if (state.requestedSync) {
            referenceTime = state.requestedSync;
            label = "sync requested at ";
        } else {
            return '¯\\_(ツ)_/¯';
        }

        let PoD = 'am';
        let h = referenceTime.getHours();
        
        if (h > 12) {
            h = h - 12;
            PoD = 'pm';
        }

        let timeString = h + ':' + referenceTime.getMinutes() + ':' + referenceTime.getSeconds() + ' ' + PoD;

        return label + timeString;
    }
}

const actions = {
    registerNewPosition(context, position) {
        context.commit('newlyAddedPosition', position);
    },
    registerPositionList(context, positionList) {
        for ( let i = 0; i < positionList.length; i++) {
            context.commit('newlyAddedPosition', positionList[i]);
        }
    },
    removePositionFromActionable(context, pid) {
        context.commit("removeActionablePosition", pid);
    },
    removePositionsFromActionable(context, pidList) {
        for (var i = 0; i < pidList.length; i++) {
            context.commit("removeActionablePosition", pidList[i]);
        }
    },
    ingestActionablePositions(context, positions) {
        context.commit("saveActionablePositions", positions)
    },
    setPositionsOpenToday(context, positionCount) {
        context.commit('setOpenToday', positionCount)
    },
    setPositionsClosedToday(context, positionCount) {
        context.commit('setClosedToday', positionCount)
    }
}

const mutations = {
    setOpenToday(state, positionCount) {
        state.openToday = positionCount;
    },
    setClosedToday(state, positionCount) {
        state.closedToday = positionCount
    },
    saveActionablePositions(state, positions) {
        state.actionablePositions = positions;
    },
    removeActionablePosition(state, pid) {
        for (var i = 0; i < state.actionablePositions.length; i++) {
            if (state.actionablePositions[i].pid == pid) {
                state.actionablePositions.splice(i, 1);
            }
        }
    },
    newlyAddedPosition(state, position) {
        state.positionMap.set(position.pid, position);
        if (!state.lastOpenPositions.includes(position.pid)) {
            state.lastOpenPositions.push(position.pid);
        }
    },
    updateLastSync(state) {
        state.lastSync = new Date;
    },
    updateRequestedSync(state) {
        state.requestedSync = new Date;
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}