<template>
    <div>
        <b-row v-for="(event, rowIndex) in events" :key="event.public_id" :class="eventClass(rowIndex)" class="py-2">
            <b-col>
                <b-icon :icon="eventIcon(event)" v-if="event.type == 'asset' && event.subtype == 'buy'" class="text-danger"></b-icon>
                <b-icon :icon="eventIcon(event)" v-if="event.type == 'asset' && event.subtype == 'bonus_exit'" class="text-warning"></b-icon>
                <b-icon :icon="eventIcon(event)" v-if="event.type == 'asset' && event.subtype == 'reinvest'" class="text-warning"></b-icon>
                <b-icon :icon="eventIcon(event)" v-if="event.type == 'asset' && event.subtype == 'exit'" class="text-success"></b-icon>
                <b-icon :icon="eventIcon(event)" v-if="event.type == 'asset' && event.subtype == 'conviction'" class="text-info"></b-icon>

                <span v-if="eventIcon(event)"> &nbsp;</span> <span v-html="formatMessage(event)"></span> <span class="text-secondary">&nbsp; {{ formatTimeFromISO(event.timestamp) }}</span>
            </b-col>
        </b-row>
        <div class="text-center pb-3">
            <div class="pt-3">
                <b-link v-if="eventList.length > eventsToShow" @click.prevent="toggleShowMore" class="text-white show-more-less" href="#">
                    <span v-if="!showMore">Show more (+{{ eventList.length - eventsToShow }})</span>
                    <span v-else>Show less</span>
                </b-link>
            </div>
        </div>
    </div>
</template>

<script>
import formatter from '@/mixins/formatter'

export default {
    name: "EventList",
    
    props: ['eventList'],
    mixins: [formatter],
    data() {
        return {
            showMore: false,
            minEventsToShow: 5,
            eventTypes: {
                asset: {
                    exit: 'text-success',
                    reinvest: 'text-warning',
                    buy: 'text-danger'
                }
            },
            eventIcons: {
                asset: {
                    exit: 'box-arrow-up',
                    bonus_exit: 'award',
                    reinvest: 'arrow-down-up',
                    buy: 'box-arrow-in-down',
                    conviction: 'file-diff'
                }
            }
        }
    },
    methods: {
        eventClass(rowIndex) {
            let returnList = [this.rowClass(rowIndex)];
            
            if (rowIndex === 0) returnList.push('pt-3');
            //if (rowIndex + 1 == this.eventList.length) returnList.push('pb-0');

            //if (event.type in this.eventTypes && event.subtype in this.eventTypes[event.type]) returnList.push(this.eventTypes[event.type][event.subtype]);



            return returnList
        },
        eventIcon(event) {
            if (event.type in this.eventIcons && event.subtype in this.eventIcons[event.type]) return this.eventIcons[event.type][event.subtype];

            return null
        },
        formatMessage(event) {
            let returnString = ''

            if (event.type == 'asset') {
                if (event.subtype == 'buy') returnString += event.message;
                else if (event.subtype == 'reinvest') returnString += `Reinvested ${event.data.reinvested_pids.length} at ${event.data.target_price}`;
                else if (event.subtype == 'exit') returnString += `Exit $${event.data.exit_size} (${event.data.exit_pids.length}) at ${event.data.target_price}`;
                else if (event.subtype == 'conviction') {
                    returnString += `Conviction <code>${this.convictionString(event.data.old_conviction)}</code> &#x21E2; <code>${this.convictionString(event.data.conviction)}</code>`;
                    
                    if (event.data.current_price) returnString += ` @ ${event.data.current_price}`;
                    if (event.data.lowest_open) {
                        let percentFromLowest = ((event.data.current_price/event.data.lowest_open -1)*100).toFixed(2)

                        let strPercentFromLowest = ''
                        if (percentFromLowest > 0) strPercentFromLowest = `+${percentFromLowest}%`
                        else strPercentFromLowest = `${percentFromLowest}%`

                        returnString += ` (${strPercentFromLowest} from lowest open)`;
                    }
                }
                else returnString += event.message;
            }
            else returnString += event.message;

            return returnString;
        },
        toggleShowMore() {
            this.showMore = !this.showMore;
        },
        convictionString(conviction) {
            if (!conviction) return 'Regular'
            return conviction.charAt(0).toUpperCase() + conviction.slice(1)
        }
    },
    computed: {
        events() {
            if (this.showMore) return this.eventList;
            else {
                if (this.eventList.length > this.eventsToShow) return this.eventList.slice(0, this.eventsToShow);
                else return this.eventList;
            }
        },
        eventsToShow() {
            return ((this.eventsToday > this.minEventsToShow) ? this.eventsToday  : this.minEventsToShow);
        },
        eventsToday() {
            let events = 0
            this.eventList.forEach((event) => {
                if (event.timestamp.split('T')[0] == (new Date).toISOString().split('T')[0]) events += 1;
            });
            return events
        }
    }
}
</script>

<style scoped>

a.show-more-less:click { text-decoration: none; }

</style>