const state = () => ({
    bucketList: [],

})


const getters = {

}

const actions = {
    addBuckets: (context, bucketList) => {
        context.commit('storeBuckets', bucketList)
    }

}

const mutations = {
    storeBuckets: (state, bucketList) => {
        state.bucketList = bucketList
    }

}

export default {
    state,
    getters,
    actions,
    mutations,
}