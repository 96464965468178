<template>
    <b-table 
        striped 
        borderless
        small

        :items="assetList" 
        :fields="tableFields"
        sort-by="symbol"
        class="table table-dark position_table mb-0"
        thead-class="hidden_header"
        id="asset-table"
        >

        <template #cell(asset_data)="data">
            <b-row class="pl-2">
                <b-col>
                    <asset-symbol :asset="data.item" />
                    <span 
                        class="text-secondary asset-symbol-positions-no"
                        v-if="data.item.cached_stats && 'open_positions_no' in data.item.cached_stats"
                        >
                        {{ data.item.cached_stats.open_positions_no }}
                    </span>
                </b-col>
                <b-col cols="3" class="format-price">
                    <span v-if="data.item.meta && 'lowest_position' in data.item.meta && data.item.meta.lowest_position">
                        {{ formatPrice(JSON.parse(data.item.meta.lowest_position).open_price) }}
                    </span>
                    <span v-else>
                        N/A
                    </span>
                </b-col>
                <b-col class="format-price">
                    {{ formatPrice(data.item.price) }}
                    <span v-if="data.item.meta && ['100k'].includes(data.item.meta.conviction)">
                        <price-distance :openPrice="JSON.parse(data.item.meta.lowest_position).open_price" :currentPrice="data.item.price" />
                    </span>
                </b-col>
            </b-row>
        </template>
    </b-table>
</template>

<script>
import formatter from '../../mixins/formatter';

import AssetSymbol from '../style/AssetSymbol.vue';
import PriceDistance from '../style/PriceDistance.vue';

export default {
    name: 'AssetList',
    props: ['assetList'],
    mixins: [formatter],
    components: {
        AssetSymbol,
        PriceDistance
    },

    data() {
        return {
            tableFields: [
                {
                    key: 'score',
                    label: 'score',
                    thClass: 'd-none', 
                    tdClass: 'd-none',                 
                },
                {
                    key: 'asset_data',
                    label: 'asset',
                    tdClass: 'py-3', 
                },
            ],
        }
    },
    methods: {
    }
};
</script>

<style>
.hidden_header {
    display: none;
}
</style>