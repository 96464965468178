<template>
    <div>
        <rounded-container variant="dark">
            <template v-slot:title>Experiments</template>
            
            <b-container class="py-3">
                <b-row>
                    <b-col><router-link to="">Groups open in the last 12 months </router-link> - Daca e format din pozitii stale, oricum se inchide in 12 luni natural</b-col>
                </b-row>
                <b-row>
                    <b-col><router-link to="">Anomalies</router-link> - Grupuri care au pozitii open dupa 12 luni.</b-col>
                </b-row>
                <b-row>
                    <b-col><router-link to="">List assets by number of open positions</router-link></b-col>
                </b-row>
            </b-container>
        </rounded-container>

        <rounded-container>
            <template v-slot:title>Experiments</template>
            <ul>
                <li></li>
            </ul>
        </rounded-container>
    </div>
</template>

<script>

//import { mapState } from "vuex";
//import axios from 'axios';
import RoundedContainer from './style/RoundedContainer.vue'

export default {
    name: "Experiments",
    components: {
        RoundedContainer
    },
    data() {
        return {
        }
    }
}
</script>
