<template>
    <div>
        <rounded-container variant="dark">
            <template v-slot:title>
                <slot name="title">Options</slot>
            </template>

            <b-row class="p-3">
                <b-col>
                    <b-form-select v-model="selected" :options="selectOptions" size="sm"></b-form-select>
                </b-col>
                <b-col>
                    <b-form-select v-model="selectedSort" :options="sortOptions" size="sm"></b-form-select>
                </b-col>
            </b-row>
        </rounded-container>

        <rounded-container variant="dark">
            <b-table
                hover
                dark
                striped
                borderless
                thead-class="hidden_header"
                class="mb-0"

                :fields="columns"
                :items="rowsIncludingTotal"
                
            >
                <template #cell(symbol)="data">
                    <span v-if="data.item.asset === 'Total'" class="font-weight-bold">Total</span>
                    <asset-symbol v-else :asset="data.item.asset" />
                </template>

                <template #cell(percentage)="data">
                    <span v-if="data.item.asset !== 'Total'">
                        <span v-if="data.item.totalValue / totalValue * 100 <= 1" class="text-secondary">
                            {{ (data.item.totalValue / totalValue * 100).toFixed(2) }}%
                        </span>
                        <span v-else>
                            {{ (data.item.totalValue / totalValue * 100).toFixed(1) }}%
                        </span>
                    </span>
                </template>

                <template #cell(value)="data">
                    <span v-if="data.item.asset === 'Total'" class="font-weight-bold">${{ formatHoldings(data.item.totalValue) }}</span>
                    <span v-else>${{ formatHoldings(data.item.totalValue) }}</span>
                </template>

                <template #cell(shares)="data">
                    <span v-if="data.item.asset === 'Total'"></span>
                    <span v-else>{{ formatHoldings(data.item.shares) }}</span>
                </template>
            </b-table>
        </rounded-container>
    </div>    
</template>

<script>

// import axios from 'axios';
import { mapState } from 'vuex';
import formatter from '@/mixins/formatter'

import RoundedContainer from '@/components/style/RoundedContainer.vue'
import AssetSymbol from '@/components/style/AssetSymbol.vue';

export default {
    name: 'Portfolio',
    components: {
        RoundedContainer,
        AssetSymbol
    },
    mixins: [formatter],
    data() {
        return {
            selected: null,
            selectedSort: 'valueDesc',
            sortOptions: [
                { value: 'valueDesc', text: 'Value (desc)' },
                { value: 'symbolAsc', text: 'Symbol (asc)' }
            ],
            columns: [
                { key: 'symbol', label: "" },
                { key: 'percentage', label: "% of"},
                { key: 'value', label: "Value" },
                { key: 'shares', label: "Holdings" },
            ],
        }
    },
    mounted() {
        for (const eachOption of this.selectOptions) {
            if (eachOption.value === 'market:stock') {
                this.selected = 'market:stock';
                break;
            }
        }

    },
    computed: {
        ...mapState({
            assets: state => state.assets.assetList,
        }),
        activeAssets() {
            if (!this.assets) {
                return [];
            }

            return this.assets.filter(asset => asset && asset.status !== 'disabled');
        },
        assetsByPublicId() {
            if (!this.activeAssets) {
                return {};
            } 

            return this.activeAssets.reduce((acc, asset) => {
                acc[asset.public_id] = asset;
                return acc;
            }, {});
        },
        balancesByBucket() {
            if (!this.activeAssets) {
                return {};
            } 

            return this.activeAssets.reduce((acc, asset) => {
                for (const balance of asset.balances) {
                    if (!acc[balance.bucket.public_id]) {
                        acc[balance.bucket.public_id] = [];
                    }

                    acc[balance.bucket.public_id].push({
                        ...balance,
                        asset_public_id: asset.public_id,
                    });
                }

                return acc;
            }, {});
        },
        assetPublicIdsByMarket() {
            if (!this.activeAssets) {
                return {};
            } 

            return this.activeAssets.reduce((acc, asset) => {
                if (!asset.market) {
                    return acc;
                }

                if (!acc[asset.market]) {
                    acc[asset.market] = [];
                }

                acc[asset.market].push(asset.public_id);
                return acc;
            }, {});
        },
        selectOptions() {
            const options = [];
            for (const eachMarket of Object.keys(this.assetPublicIdsByMarket)) {
                options.push({
                    value: 'market:' + eachMarket,
                    text: `${eachMarket} market`,
                });
            }

            for (const eachBucketPublicId of Object.keys(this.balancesByBucket)) {
                options.push({
                    value: 'bucket:' + eachBucketPublicId,
                    text: this.balancesByBucket[eachBucketPublicId][0].bucket.name,
                });
            }

            
            return options;
        },
        rows() {
            let rows = [];

            if (!this.selected) {
                return rows;
            }

            const [type, id] = this.selected.split(':');

            if (type === 'market') {
                const assetPublicIds = this.assetPublicIdsByMarket[id];
                for (const eachAssetPublicId of assetPublicIds) {
                    const asset = this.assetsByPublicId[eachAssetPublicId];
                    const row = {
                        asset: asset,
                        shares: asset.balances.reduce((acc, balance) => {
                            return acc + balance.shares;
                        }, 0)
                    }
                    row.totalValue = row.asset.price * row.shares;

                    if (row.shares > 0) {
                        rows.push(row);
                    }
                }
            } else if (type === 'bucket') {
                for (const eachBalance of this.balancesByBucket[id]) {
                    const row = {
                        asset: this.assetsByPublicId[eachBalance.asset_public_id],
                        shares: eachBalance.shares,
                    }
                    row.totalValue = row.asset.price * row.shares;
                    
                    if (row.shares > 0) {
                        rows.push(row);
                    }
                }
            }

            if (this.selectedSort === 'symbolAsc') {
                rows = rows.sort((a, b) => a.asset.symbol.localeCompare(b.asset.symbol));
            } else {
                rows = rows.sort((a, b) => (a.totalValue < b.totalValue) ? 1 : -1);
            }

            return [
                ...rows
            ]
        },
        totalValue() {
            return this.rows.reduce((acc, row) => acc + row.totalValue, 0);
        },
        rowsIncludingTotal() {
            return [
                {
                    asset: 'Total',
                    shares: 0,
                    totalValue: this.totalValue
                },
                ...this.rows
            ];
        }
    },
}
</script>

<style>
.hidden_header {
    display: none;
}
</style>