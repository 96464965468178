<template>
    <div>
        <rounded-container v-if="noStalePositionsFound" variant="dark">
            <template v-slot:title>Stale Positions</template>
            
            <div class="text-center p-3">
                No stale positions found.
            </div>

        </rounded-container>
        <rounded-container v-else>
            <template v-slot:title>Stale Positions</template>

            <b-table
                    hover
                    dark
                    :fields="positionFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :items="stalePositions"
                    thead-class="hide_header"
                    class="m-0"
                >
                <template v-slot:cell(ticker)="data">
                    <router-link
                        class="security_symbol" :class="securityClasses(data.item)"
                        :to="{ name: 'asset', params: { symbol: data.item.ticker, pid: data.item.pid } }"
                    >{{ data.item.ticker }}</router-link>
                </template>

                <template v-slot:cell(positionSize)="data">
                    <strong>${{ data.item.size }}</strong>
                    <br />
                    <small>{{ data.item.shares }}</small>
                </template>

                <template v-slot:cell(openPrice)="data">
                    <strong>${{ data.item.open_price }}</strong> &#x21E2;
                    <br />
                    <small>{{ data.item.open_date }}</small>
                </template>

                <template v-slot:cell(currentPrice)="data">
                    <strong>${{ data.item.close_price }}</strong>
                    <br />
                    <b-button 
                        v-if="activeButtons.includes(data.item.pid)"
                        variant="success"
                        size="sm"
                        href="#app" 
                        @click.prevent="processReinvest(data.item.pid)"
                        >Reinvest</b-button>
                    <b-spinner
                        v-else
                        small
                        type="grow" 
                        class="button_spinner mx-3"
                        variant="success"
                    ></b-spinner>
                </template>

                <template v-slot:cell(closePrice)="data">
                    <strong>${{ format_price(data.item.current_price) }}</strong>
                    <br />
                    <b-badge v-if="data.item.indicator > 0" variant="dark">{{ data.item.indicator }}%</b-badge>
                    <b-badge v-else variant="warning">{{ data.item.indicator }}%</b-badge>
                </template>
            </b-table>
        </rounded-container>

        

        <rounded-container variant="dark">
            <template v-slot:title>
                Reinvested in the past 30 days 
                <span class="text-secondary">${{ staleClosed.length * defaultPositionSize }}</span>
            </template>

            <div class="oddRow p-3">
                <span v-for="assetSummary in closedSummary" :key="assetSummary.ticker" class="mr-3 text-secondary">
                    <strong>{{ assetSummary.positions }}x</strong>&nbsp;{{ assetSummary.ticker }}&nbsp;${{ assetSummary.positions * defaultPositionSize }}
                </span>
                
            </div>

            <div v-if="staleClosed.length">
                <div v-for="(position, rowIndex) in staleClosed" 
                    :key="position.pid" 
                    :class="rowClass(rowIndex)" 
                    class="p-3"
                    >
                    <b-row no-gutters class="pt-1">
                        <b-col cols=3>
                            <asset-symbol :asset="getAssetBySymbol(position.ticker)" />
                        </b-col>
                        <b-col cols=3>
                            <span class="formatPrice">${{ format_price(position.current_price) }}</span>
                        </b-col>
                        <b-col cols=3>
                            <span class="formatPrice">${{ position.open_price }}</span> &#x21E2;
                        </b-col>
                        <b-col cols=3>
                            <span class="formatPrice">${{ position.close_price }}</span>
                        </b-col>
                    </b-row>
                    <b-row no-gutters class="pb-3">
                        <b-col cols=3>
                            <span v-if="position.size != 50" class="text-danger">
                                ${{ position.size }}
                            </span>
                        </b-col>
                        <b-col cols=3>
                            <b-badge v-if="position.indicator > 0" variant="dark">{{ position.indicator }}%</b-badge>
                            <b-badge v-else variant="warning">{{ position.indicator }}%</b-badge>
                        </b-col>
                        <b-col cols=3 class="text-secondary">
                            {{ position.open_date }}
                        </b-col>
                        <b-col cols=3 class="text-secondary">
                            {{ position.close_date }}
                        </b-col>
                    </b-row>
                </div>
            </div>
        </rounded-container>

    </div>
</template>


<script>

import axios from 'axios';
import RoundedContainer from '../components/style/RoundedContainer.vue';
import AssetSymbol from '../components/style/AssetSymbol.vue';

export default {
    name: 'StalePositions',
    components: {
        RoundedContainer,
        AssetSymbol
    },

    data() {
        return {
            gotData: false,
            activeButtons: [],
            stalePositions: [],
            staleClosed: [],
            closedSummary: [],
            sortBy: "open_date",
            sortDesc: false,
            positionFields: [
                { key: "ticker", label: "" },
                { key: "positionSize", label: "Amount" },
                { key: "openPrice", label: "Open" },
                { key: "currentPrice", label: "Current" },
                { key: "closePrice", label: "Close" },
            ],
            defaultPositionSize: 50,
        }
    },
    methods: {
        rowClass(rowIndex) {
            if (rowIndex % 2) return 'oddRow';

            return 'evenRow'
        },
        getAssetBySymbol(symbol) {
            //console.log(this.$store)
            return this.$store.getters.getAssetBySymbol(symbol);
        },
        securityClasses(item) {
            if (item.market == 'crypto') return 'symbol_color_crypto';

            return '';
        },
        format_price(price) {
            if (price >= 500) {
                price = Math.round(price)
            }
            else if (price >= 50) {
                price = Math.round(price * 10) / 10
            }
            return price
        },
        processReinvest(pid) {
            const index = this.activeButtons.indexOf(pid);
            if (index > -1) {
                this.activeButtons.splice(index, 1);
            }

            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios.post(this.$store.state.baseURL[this.$store.state.env] + '/api/reinvest_position', {
                'pid': pid
            }).then(() => {
                this.retrieveStalePositions()
            }).catch(() => {
                this.activeButtons.push(pid);
                this.retrieveStalePositions()
            });
        },
        retrieveStalePositions() {
            this.gotData = false;

            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/positions/stale')
                .then((response) => {
                    this.activeButtons = [];

                    this.stalePositions = response.data.stale_positions;
                    this.staleClosed = response.data.stale_closed;
                    for (let pos of this.stalePositions) {
                        this.activeButtons.push(pos.pid)
                    }

                    let summary = {};
                    for (let position of this.staleClosed) {
                        if (!(position.ticker in summary)) {
                            summary[position.ticker] = 0;
                        }

                        summary[position.ticker] += 1;
                    }
                    summary = Object.fromEntries(
                            Object.entries(summary).sort(([,a],[,b]) => b-a)
                        );

                    this.closedSummary = []
                    for (const [key, value] of Object.entries(summary)) {
                        this.closedSummary.push({ ticker: key, positions: value })
                    }

                    this.gotData = true;
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
    },
    created() {
        this.retrieveStalePositions();
    },
    computed: {
        noStalePositionsFound() {
            if (!this.stalePositions.length) return true;
            return false;
        },
    }
}

</script>


<style>
.hide_header {
    display: none;
}
.symbol_color_crypto, .symbol_color_crypto:hover { color: #e83e8c; }
</style>