const state = () => ({
    accessToken: localStorage.getItem('access_token') || null,
})


const getters = {
    loggedIn(state) {
        return state.accessToken !== null;
    },
}

const actions = {}

const mutations = {
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    destroyAccessToken(state) {
        state.accessToken = null;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}