<template>
    <div id="login_view">
        <h1>Warren</h1>
        <b-form acton="#" @submit.prevent="onSubmit" v-if="show">
            <b-form-group id="input-group-username" label="Email:" label-for="username">
                <b-form-input
                    id="username"
                    v-model="form.username"
                    type="email"
                    required
                    placeholder="Enter email"
                ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-password" label="Password:" label-for="password">
                <b-form-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    required
                    placeholder="Enter password"
                ></b-form-input>
            </b-form-group>

            <div v-if="buttonLoadingState">
                <b-button type="submit" variant="primary"><b-spinner small type="grow"></b-spinner></b-button>
            </div>
            <div v-else>
                <b-button type="submit" variant="primary">Get in</b-button>
            </div>

        </b-form>

        <div v-if="errorMessage">
            <b-alert show variant="danger" class="login_error">{{ errorMessage }}</b-alert>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
    name: "Login",
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            show: true,
            errorMessage: '',
            buttonLoadingState: false,
        };
    },
    created() {
        this.redirectIfLoggedIn()
    },
    methods: {
        onSubmit() {
            this.errorMessage = '';
            this.buttonLoadingState = true;

            this.$store
                .dispatch("retrieveToken", {
                    username: this.form.username,
                    password: this.form.password
                })
                .then(() => {
                    this.$router.push({ name: "dashboard" });
                })
                .catch((error) => {
                    if (error.response.status == 418) {
                        this.errorMessage = error.response.data
                        this.buttonLoadingState = false;
                    }
                    else {
                        this.errorMessage = 'Logged invalid login attempt.'
                        this.buttonLoadingState = false;
                    }
                });
        },
        redirectIfLoggedIn() {
            if (this.loggedIn) {
                this.$router.push({ name: "dashboard" });
            }
        }
    },
    computed: {
        ...mapGetters([
            'loggedIn'
        ])
    }
};
</script>

<style scoped>
#login_view {
    margin: 0px 40px;
}
#login_view h1 {
    font-weight: 800;
    font-size: 100px;
    display: inline-block;
    background: linear-gradient(130deg,#6610f2 -6.8%,#e83e8c 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
}
.login_error {
    margin: 20px 0px;
}
</style>
