<script>
import { Bubble, mixins } from 'vue-chartjs';

export default {
    name: 'BubbleChart',
    extends: Bubble,
    mixins: [mixins.reactiveProp],
    props: {
        chartData: {
            type: Object,
            required: true
        },
    },
    data(){
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                },
                animation: false,
            }
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.options);
    }
};
</script>
