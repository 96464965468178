<template>
    <div>
        <div v-if="boostsList.length">
            <b-table 
                striped 
                :items="boostsList" 
                :fields="tableFields"
                class="table table-dark m-0"
                :sort-by.sync="tableSortBy"
                :sort-desc.sync="tableSortDesc"
            >
            </b-table>
        </div>
        <div v-else class="text-center my-5">
            No boosted asset found.
        </div>
    </div>    
</template>

<script>

import axios from 'axios';

export default {
    name: 'ListBoosts',
    props: ['requestUpdate'],
    data() {
        return {
            boostsList: [],

            tableFields: [
                {
                    key: 'asset.symbol',
                    label: 'asset',
                    sortable: true
                },
                {
                    key: 'type',
                    label: 'type',
                    sortable: false
                },
                {
                    key: 'start_date',
                    label: 'starts',
                    formatter: 'formatDate',
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: 'ends',
                    formatter: 'formatDate',
                    sortable: true
                },
            ],
            tableSortBy: 'start_date',
            tableSortDesc: false,
        }
    },
    created() {
        this.retrieveBoostList();
    },
    watch: {
        requestUpdate: function(newValue) {
            if (newValue == true) {
                this.retrieveBoostList();
                this.$emit('update:requestUpdate', false);
            }
        }
    },
    methods: {
        retrieveBoostList() {
            this.gotAssetData = false;

            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/boosts')
                .then((response) => {
                    this.boostsList = response.data;
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
        formatDate(givenDate) {
            let shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            let today = new Date();
            let date = new Date(givenDate);
            let returnString = "".concat(shortMonths[date.getMonth()], " ", date.getDate()); 
            if (today.getFullYear() != date.getFullYear()) returnString = returnString.concat(", ", date.getFullYear());
            return returnString;
        }

    },

}
</script>

<style>

</style>