<template>
    <span>
        <router-link :class="classes"
            :to="{ name: 'asset', params: { symbol: asset.symbol, assetContext: asset }}"
            >{{ asset.symbol }}<span :class="offsetClass" v-if="offsetSymbol">{{ offsetSymbol }}</span> 
            {{ emojis }}</router-link>
    </span>
</template>
<script>
export default {
    name: 'AssetStyle',
    props: ['asset', 'pid'],

    data() {
        return {
            classes: 'security_symbol',
            emojis: '',
            offsetSymbol: '',
            enoughSurplus: false ,
        }
    },

    created() {
        this.doStuff();
    },
    methods: {
        doStuff(asset) {
            // this needs to be separated from created()
            // if not, previously rendered instances of this component are reused
            // without resseting to its original state
            // eg: stock assets wrongfully being labeled as crypto

            let currentAsset = this.asset
            if (asset) currentAsset = asset;

            this.classes = 'security_symbol';
            this.emojis = '';
            this.offsetSymbol = '';
            this.enoughSurplus = false;

            if (currentAsset.offset && currentAsset.offset > 0) {
                this.offsetSymbol = '+';
                if (currentAsset.offset > 500) this.enoughSurplus = true;
            }
            else if (currentAsset.offset && currentAsset.offset < 0) {
                this.offsetSymbol = '-';
            }

            if (currentAsset.market == 'crypto') this.classes += ' symbol_color_crypto';
            else this.classes += ' symbol_color';
            
            // never change to acts_like to visually be able scan for *impostors*
            // !!!
            if ((this.asset.meta) && ('conviction' in this.asset.meta)) {
                if (this.asset.meta.conviction == '100k') this.emojis += '✨';
                if (this.asset.meta.conviction == 'high') this.emojis += '💰';
                if (this.asset.meta.conviction == 'low') this.emojis += '🧯';
                if (this.asset.meta.conviction == 'snoozed') this.emojis += '😴';
            }
        }
    },
    computed: {
        offsetClass() {
            if (this.enoughSurplus) return 'text-success'

            return ''
        }
    },
    watch: {
        asset(newAsset) {
            this.doStuff(newAsset);
        }
    }
}
</script>

<style>
.security_symbol {
    font-weight: bold;
}
.security_symbol:hover {
    text-decoration: none;
}
.symbol_color, .symbol_color:hover { color: #007bff; }
.symbol_color_crypto, .symbol_color_crypto:hover { color: #e83e8c; }
</style>