import { mapState } from "vuex";

export default {
    data() {
        return {
            
        }
    },
    created() {
        
    },
    methods: {
        addDraw(asset) {
            let defaultOpenPrice = null

            if ('draw_price' in asset.meta) defaultOpenPrice = asset.meta.draw_price
            
            this.$emit('asset-add-group', {
                    defaultGroupType: 'monthly_draw',
                    asset: asset,
                    defaultDrawSize: 2000,
                    defaultMultiplier: 1.1,
                    defaultOpenPrice: defaultOpenPrice,
                    defaultGroupName: this.defaultDrawName(asset),
                });
        },
        manageGroupModal(groupData) {  
            this.$emit('manage-group', groupData);
        },
        defaultDrawName(asset) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return months[(new Date()).getMonth()] + ' ' + (new Date()).getFullYear() + ' ' + asset.symbol + ' monthly draw'
        },
        hasMonthlyDraw(asset) {
            console.log('hasMonthlyDraw', asset)

            return false
        }
    },
    computed: {
        ...mapState({
            assetList: state => state.buckets.assetList,
        }),
    }
}