<template>
    <div>
        <asset-navbar :filterMarket="filterMarket">
            <template v-slot:title>Boosts</template>
            <template v-slot:subTitle>
            </template>
        </asset-navbar>

        <rounded-container>
            <template v-slot:title>Active boosts</template>

            <list-boosts :requestUpdate.sync="requestBoostListUpdate" />
        </rounded-container>
        
        <rounded-container variant="dark">
            <template v-slot:title>Add new boost</template>

            <add-boost :requestUpdate.sync="requestBoostListUpdate" />
        </rounded-container>
        
    </div>    
</template>

<script>

import AddBoost from "../components/asset/AddBoost.vue";
import ListBoosts from '../components/asset/ListBoosts.vue';
import AssetNavbar from '../components/AssetNavbar.vue';
import RoundedContainer from '../components/style/RoundedContainer.vue';

export default {
    name: 'Boosts',
    components: {
        AddBoost,
        ListBoosts,
        RoundedContainer,
        AssetNavbar 
    },
    props: ['market'],
    data() {
        return {
            requestBoostListUpdate: false,
            filterMarket: '',
        }
    },
    created() {
        this.filterMarket = this.market
    }
}
</script>

<style>

</style>