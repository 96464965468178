<template>
    <div class="modal-style">
        <h2>
            <span class="mr-2">Add Group</span>
            <span class="text-secondary">
                <span class="mr-2">{{ assetContext.symbol }}</span>
            </span>
        </h2>

        <b-form acton="#" @submit.prevent="onSubmit" autocomplete="off" class="mt-5">
            <!--
                """
                    group.type: long_buy
                    - bucket_public_id -> bucket_id
                    - name
                    - asset_symbol -> asset_id
                    - open_date -> added_time
                    - cost (value)
                    - open_price 
                    - multiplier: 1.1, 1.2, 1.3 -> deriva in starting_open
                """
            -->
            <b-row class="">
                <b-col>
                    <b-form-select 
                        v-model="form.type" 
                        :options="typeOptions"
                        name="type"
                        id="group-type"
                    >
                        <!--<template #first>
                            <b-form-select-option :value="null" disabled>-- Type --</b-form-select-option>
                        </template>-->
                    </b-form-select>
                </b-col>
                <b-col>
                    <b-form-select 
                        v-model="form.bucket" 
                        :options="bucketOptions"
                        name="bucket"
                        id="target-bucket"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Bucket --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <b-input-group>
                        <b-form-input
                            id="date-input"
                            v-model="openDateFormatted"
                            type="text"
                            autocomplete="off"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-form-datepicker
                                v-model="form.openDate"
                                value-as-date
                                button-only
                                right
                                locale="en-US"
                                aria-controls="date-input"
                            ></b-form-datepicker>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-form-input
                        type="text" 
                        name="groupName" 
                        id="groupName" 
                        placeholder="Group name"
                        v-model="form.groupName"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <div class="text-secondary mb-1">open price</div>
                    <b-form-input
                        type="text" 
                        name="open-price" 
                        id="group-open-price" 
                        :placeholder="'$'+assetContext.price"
                        v-model="form.openPrice"
                    ></b-form-input>
                </b-col>
                <b-col>
                    <div class="text-secondary mb-1">size in {{ assetContext.currency }}</div>
                    <b-form-input
                        type="text" 
                        name="cost" 
                        id="group-cost" 
                        placeholder="$cost"
                        v-model="form.cost"
                    ></b-form-input>
                </b-col>
                <b-col>
                    <div class="text-secondary mb-1">multiplier</div>
                    <b-form-input
                        type="text" 
                        name="multiplier" 
                        id="group-multiplier" 
                        placeholder="Multiplier"
                        v-model="form.multiplier"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <b-overlay
                            :show="addGroupBusy"
                            rounded
                            opacity="0.1"
                            spinner-small
                            spinner-variant="light"
                            class="d-inline-block mr-3"
                        >
                        <b-button v-if="canSubmit" type="submit" variant="primary">Add</b-button>
                        <b-button v-else disabled type="submit" variant="primary">Add</b-button>
                    </b-overlay>

                    <span v-if="form.cost && form.multiplier && form.openPrice && form.type == 'long_buy'" class="text-secondary">
                        Convert <span class="text-warning">${{ form.cost }}</span> at <span class="text-warning">${{ formatPrice(form.openPrice * parseFloat(form.multiplier)) }}</span> ({{ formatHoldings(form.cost / (form.openPrice * parseFloat(form.multiplier))) }} sh @{{ parseFloat(form.multiplier) }}x)
                    </span>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import axios from 'axios';

import formatter from '../../mixins/formatter'

export default {
    name: 'AddGroup',
    props: [
        'assetContext', 
        'groupType', 
        'defaultDrawSize', 
        'defaultMultiplier', 
        'defaultOpenPrice',
        'defaultGroupName',
    ],
    mixins: [formatter],
    components: {

    },
    data() {
        return {
            form: {
                type: ((this.groupType) ? this.groupType : 'long_buy'),
                bucket: null,
                dateAdded: null,
                
                groupName: this.defaultGroupName,
                openPrice: this.defaultOpenPrice,
                openDate: new Date(),
                cost: this.defaultDrawSize,
                multiplier: this.defaultMultiplier,

            },
            typeOptions: [
                {
                    value: 'long_buy',
                    text: 'Long Buy',
                },
                {
                    value: 'monthly_draw',
                    text: 'Monthly draw',
                }
            ],
            bucketOptions: [],
            addGroupBusy: false,
        }
    },
    created() {
        this.openPrice = this.assetContext.price

        if (!this.defaultGroupName) this.form.groupName = 'Moved to long term holdings'
        if (!this.defaultMultiplier) this.form.multiplier = 1.5

        this.getBucketOptions()
    },
    methods: {
        getBucketOptions() {
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/buckets')
                .then((response) => {
                    let buckets = response.data
                    buckets.forEach(bucket => this.bucketOptions.push({
                        text: bucket.name,
                        value: bucket.public_id,
                    }))
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
        async onSubmit() {
            if (this.canSubmit) {
                this.addGroupBusy = true;

                let payload = {
                    asset_symbol: this.assetContext.symbol,
                    type: this.form.type,
                    bucket_public_id: this.form.bucket,
                    open_date: this.openDateFormatted,
                    name: this.form.groupName,
                    open_price: this.form.openPrice,
                    cost: this.form.cost,
                    multiplier: this.form.multiplier
                }

                axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
                axios.post(
                        this.$store.state.baseURL[this.$store.state.env] + '/api/groups/add', 
                        payload, {
                            headers: {
                                // Overwrite Axios's automatically set Content-Type
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.asset) {
                            this.$store
                                .dispatch('updateAssets', [response.data.asset])
                                .then(() => this.$FModal.hide())
                        }

                        this.addGroupBusy = false; 
                    })
                    .catch((error) => {
                        console.log('AddGroup error', error);
                        this.addGroupBusy = false;    
                    });
            }
        }
    },
    computed: {
        canSubmit() {
            if (this.form.type && this.form.bucket 
                && this.form.openDate && this.form.groupName
                && this.form.openPrice && this.form.cost && this.form.multiplier) return true;
            return false;
        },
        openDateFormatted() {
            if (this.form.openDate) {
                return this.form.openDate.getFullYear()+'-'+('0' + (this.form.openDate.getMonth()+1)).slice(-2)+'-'+('0' + this.form.openDate.getDate()).slice(-2)
            }
            return null
        },
    }
}
</script>