<template>
    <div>
        <h2 class="mx-3 mt-4 mb-0 rounded-container-h2 text-muted">
            <slot name="title"></slot>
        </h2>
        <div class="rounded_wrap m-2" :class="containerStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RoundedContainer',
    props: ['variant'],

    data() {
        return {

        }
    },
    computed: {
        containerStyle() {
            return {
                dark_bg: this.variant == 'dark',
                white_bg: this.variant == 'white',
                'bg-info text-white': this.variant == 'highlight',
                'bg-secondary text-white': this.variant == 'secondary',
                navbar_bg: this.variant == 'navbar'
            }
        }
    }
}
</script>

<style>
.rounded-container-h2 {
    font-size: 14px;
    text-transform: uppercase;
}
.rounded_wrap {
    border-radius: 10px;
    overflow: hidden;
}

.white_bg {
    color: #000;
    background-color: #fff;
}
.dark_bg {
    background-color: #222;
}
.navbar_bg {
    background-color: #343a40;
    opacity: 0.9;
    filter: alpha(opacity=80);
}
.highlight_bg {
    color: #fff;
    background-color: #2E5266;
}
</style>