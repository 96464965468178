<template>
    <div>
        <div id="navbar-spacing"></div>

        <rounded-container id="asset-navbar" variant="navbar">
            <b-row class="p-2 pl-2" align-v="center">
                <b-col class="">
                    <h1>
                        <strong><slot name="title"></slot></strong><br />
                        <span class="text-secondary"><slot name="subTitle"></slot></span>
                    </h1>
                </b-col>

                <b-col class="text-right">
                    <b-button-group>
                        <b-button :to="{ name: 'assets', params: { market: filterMarket }}">
                            <b-icon icon="cash-stack" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button :to="{ name: 'groups', params: { market: filterMarket }}">
                            <b-icon icon="view-stacked" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button :to="{ name: 'boosts', params: { market: filterMarket }}">
                            <b-icon icon="lightning" aria-hidden="true"></b-icon>
                        </b-button>
                    </b-button-group>
                </b-col>
            </b-row>
        </rounded-container>
    </div>
</template>

<script>

import RoundedContainer from './style/RoundedContainer.vue'

export default {
    name: 'AssetNavbar',
    props: ['filterMarket'],
    components: {
        RoundedContainer
    },

    data() {
        return {

        }
    },
}
</script>

<style scoped>
#navbar-spacing {
    height: 100px;
}
#asset-navbar {
    position: fixed;
    z-index: 999;
    opacity: 1;
    filter: alpha(opacity=100);
    top: 0px;
    width: 100%;
    max-width: 600px;
}
#asset-navbar h1 {
    font-size: 20px;
    margin: 0px;
}
</style>