<template>
    <div class="mt-3">
        <rounded-container variant="dark">
            <!-- <template v-slot:title>
                <span v-if="!toBuy.length && !toSell.length">actionable</span>

                <span class="text-secondary">
                    <span v-if="toBuy.length"><strong>{{ toBuy.length }}</strong> to buy<span v-if="toSell.length">,</span></span> <span v-if="toSell.length"><strong>{{ toSell.length }}</strong> to sell</span>
                </span>
            </template> -->

            <actions 
                @suggest-buy-symbol="suggestBuy"
                />

            <div v-for="(byAsset, rowIndex) in sellableAssets" :key="byAsset.symbol" :class="actionRowClass(rowIndex)" class="p-3">
                
                <b-row>
                    <b-col cols="8">
                        <h4>
                            <asset-symbol :asset="byAsset.asset" /> <span>${{ formatPrice(byAsset.asset.price) }}</span>
                            <span class="text-secondary"> {{ byAsset.positions.length }}</span>
                        </h4>
                    </b-col>
                    <b-col class="text-right">
                        <!-- Daca exista 3+ ITM, atunci calculat ca pot sa fac exit -->
                        <b-button v-if="byAsset.minItm >= 3" variant="success" size="sm" @click.prevent="openProcessExit(byAsset.asset, byAsset.positions)">
                            <strong>${{ formatPrice((((byAsset.positions.length %2) ? byAsset.positions.length +1 : byAsset.positions.length) / 2) * 50) }}</strong>
                        </b-button>
                        <!-- Daca exista 0-2 ITM, atunci buton de REINVEST pentru ca situatie -->
                        <b-button v-else variant="warning" size="sm" @click.prevent="openProcessExit(byAsset.asset, byAsset.positions)">
                            REINVEST
                        </b-button>
                    </b-col>
                </b-row>
                
                <div v-if="'oneLiner' in byAsset.asset.meta && byAsset.minItm >= 3" class="text-info">
                    {{ byAsset.asset.meta.oneLiner }}
                </div>

                <compact-position-list :positionList="byAsset.positions" class="pt-2" />
            </div>

            <div v-if="sellableAssets.length && underWaterAssetList.length" class="text-center bg-dark">
                - - -
            </div>

            <div class="p-3 under-water-asset" v-for="(oneAsset, rowIndex) in underWaterAssetList" :key="oneAsset.symbol" :class="actionRowClass(rowIndex)">
                <b-row>
                    <b-col cols="8">
                        <h4>
                            <asset-symbol :asset="oneAsset.asset" /> <span>${{ formatPrice(oneAsset.asset.price) }}</span>
                        </h4>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="danger" size="sm" @click.prevent="suggestBuy(oneAsset.symbol)">
                            BUY
                        </b-button>
                    </b-col>
                </b-row>

                <div v-if="'oneLiner' in oneAsset.asset.meta" class="text-info">
                    {{ oneAsset.asset.meta.oneLiner }}
                </div>

                <b-row class="small pt-2">
                    <b-col class="formatPrice">{{ formatTimeFromISO(oneAsset.lowestPosition.open_time) }}</b-col>
                    
                    <b-col class="formatPrice text-white" v-if="oneAsset.indicator > -5">{{ Math.round(oneAsset.indicator * 100) / 100 }}%</b-col>
                    <b-col class="formatPrice text-warning" v-else-if="oneAsset.indicator > -10">{{ Math.round(oneAsset.indicator * 100) / 100 }}%</b-col>
                    <b-col class="formatPrice text-danger" v-else>{{ Math.round(oneAsset.indicator * 100) / 100 }}%</b-col>
                    
                    <b-col class="formatPrice" cols="5">{{ formatPrice(oneAsset.lowestPosition.open_price) }} &#x21E2; {{ formatPrice(oneAsset.lowestPosition.close_price) }}</b-col>
                </b-row>

                <!--<div>{{ oneAsset.asset }}</div>-->
            </div>
        </rounded-container>
    </div>
</template>

<script>

import formatter from '../mixins/formatter'

import { mapState } from "vuex";

import Actions from './Actions.vue';
import RoundedContainer from './style/RoundedContainer.vue';
import AssetSymbol from './style/AssetSymbol.vue';

import ProcessExit from '../components/overlays/ProcessExit.vue'
import CompactPositionList from '@/components/asset/partials/CompactPositionList.vue';

export default {
    name: "Positions",
    components: {
        Actions,
        RoundedContainer,
        AssetSymbol,
        CompactPositionList,
    },
    mixins: [formatter],
    data() {
        return {
            sortBy: "indicator",
            sortDesc: true,
            positionFields: [
                { key: "ticker", label: "" },
                { key: "positionSize", label: "Amount" },
                { key: "openPrice", label: "Open" },
                { key: "currentPrice", label: "Current" },
                { key: "closePrice", label: "Close" },
            ],
            modalStyle: { 
                backgroundColor: '#000',

                contentStyles: { 
                    borderRadius: '10px 10px 0px 0px',
                    borderTop: '3px solid #333'
                } 
            },
        };
    },
    methods: {
        getAssetBySymbol(symbol) {
            //console.log(this.$store)
            return this.$store.getters.getAssetBySymbol(symbol);
        },
        rowClass(item, type) {
            // egde cases: no item or it's not a row
            if (item && type === "row") {
                return "table-".concat(this.indicatorClass(item));
            } else {
                return;
            }
        },
        actionRowClass(rowIndex) {
            if (rowIndex % 2) return 'oddRow';

            return 'evenRow'
        },
        indicatorClass(item) {
            let asset = this.$store.getters.getAssetBySymbol(item.ticker)

            if ((item.indicator < 0) && (asset.acts_like_conviction == 'snoozed')) return "irrelephant";

            if (item.indicator >= 10) return "success";
            if (item.indicator >= 9) return "info";
            if (item.indicator >= 8) return "meh";
            if (item.indicator < 0) return "irrelephant"; // tot ce urmeaza devine useless
            if (item.indicator <= -10) return "danger";
            if (item.indicator <= -5) {
                if (asset.acts_like_conviction == 'low') { return "irrelephant"; }
                else return "warning";
            }
            if (item.indicator <= -2) {
                if (asset.acts_like_conviction == 'high') { return "negative"; }
                else return "irrelephant";
            }
            return "irrelephant";
        },
        suggestBuy(symbol) {
            this.$emit('suggest-buy', symbol);
        },
        processMinPositionsItm(currentPrice, lowestPositions) {
            let count = 0;
            for (let i=0; i<lowestPositions.length; i++) {
                if (JSON.parse(lowestPositions[i]).open_price < currentPrice) count++;
            }
            return count;
        },
        openProcessExit(asset, positions) {
            this.$FModal.show(
                { 
                    component: ProcessExit, 
                    ...this.modalStyle
                }, 
                { 
                    currentAsset: asset,
                    positionList: positions
                }
            )
        },
    },
    computed: {
        ...mapState(['userData']),
        ...mapState({
            positions: state => state.positions
        }),
        toSell() {
            return this.$store.getters.actionableToSell
        },
        toBuy() {
            return this.$store.getters.actionableToBuy
        },
        actionsCount() {
            return this.$store.getters.actionableToSell.length + this.$store.getters.actionableToBuy.length
        },
        underWaterAssetList() {
            let assetList = []
            let allAssets = this.$store.state.assets.assetList;

            console.log('-----')

            for (let i=0; i<allAssets.length; i++) {
                if (('cached_stats' in allAssets[i] && 'lowest_open_position' in allAssets[i].cached_stats && allAssets[i].cached_stats.lowest_open_position) 
                && (allAssets[i].conviction != 'snoozed')) { 
                    let lowestPosition = JSON.parse(allAssets[i].cached_stats.lowest_open_position)

                    if (allAssets[i].price < lowestPosition.open_price) {
                        // filter to match buy level according to conviction
                        if ((allAssets[i].price / lowestPosition.open_price <= 0.98 && allAssets[i].acts_like_conviction == 'high')
                        || (allAssets[i].price / lowestPosition.open_price <= 0.95 && allAssets[i].acts_like_conviction == null)
                        || (allAssets[i].price / lowestPosition.open_price <= 0.90 && allAssets[i].acts_like_conviction == 'low')) {
                            assetList.push({
                                symbol: allAssets[i].symbol,
                                indicator: ((allAssets[i].price / lowestPosition.open_price) - 1) * 100,
                                asset: allAssets[i],
                                lowestPosition: lowestPosition
                            });
                        }
                    }
                }
                else continue
            }
            
            return assetList.sort((a, b) => (a.indicator < b.indicator) ? 1 : -1)
        },
        sellableAssets() {
            let actionablePositions = this.positions.actionablePositions;
            let byAsset = {}
            for (let i=0; i<actionablePositions.length; i++) {
                if (actionablePositions[i].indicator >= 8) {
                    if (actionablePositions[i].ticker in byAsset) {
                        byAsset[actionablePositions[i].ticker].push(actionablePositions[i])
                    }
                    else {
                        byAsset[actionablePositions[i].ticker] = [actionablePositions[i]]
                    }
                }
            }

            let returnData = [];
            for (let symbol of Object.keys(byAsset)) {
                let currentAsset = this.getAssetBySymbol(symbol);
                returnData.push({
                    symbol: symbol,
                    asset: currentAsset,
                    minItm: this.processMinPositionsItm(currentAsset.price, currentAsset.cached_stats.lowest5_open_positions),
                    positions: byAsset[symbol]
                })
            }
            
            return returnData;
        }
    },
};
</script>

<style>

.under-water-asset .evenRow {
    background-color: #FFD9DA;
}
.under-water-asset .oddRow {
    background-color: #FFD9DA;
}


.hide_header {
    display: none;
}

tr.table-success {
    color: #155724;
}

.table-irrelephant {
    display: none;
}
.table-warning, .table-danger {
    color: #000;
}
.symbol_color_crypto, .symbol_color_crypto:hover { color: #e83e8c; }

</style>
