<template>
    <div>
        <asset-navbar :filterMarket="filterMarket">
            <template v-slot:title>
                <span v-if="market" class="ml-1">{{ market }} market</span>
                <span v-else class="ml-1">???</span>
            </template>
            <template v-slot:subTitle>
            </template>
        </asset-navbar>

        <div v-if="filterMarket && assets.length">
            <div v-if="filterMarket == 'stock' || filterMarket == 'crypto'">
                <rounded-container variant="dark" v-for="(conviction, convictionLabel) in convictionOptions" :key="conviction">
                    <template v-slot:title>
                        {{ convictionLabel }}
                        <span class="text-secondary">
                            {{ assets.filter(asset => asset.market == filterMarket && asset.conviction === conviction).length }}</span>
                    </template>

                    <list-assets-table 
                        ref="assetTable" 
                        :assetList="assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.conviction === conviction)" 
                        />
                </rounded-container>

                <rounded-container>
                    <template v-slot:title>
                        snoozed
                        <span class="text-secondary">{{ assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'snoozed'
                            && asset.status != 'disabled'
                            ).length }}</span>
                    </template>
                    <list-assets-table 
                        ref="assetTable" 
                        :assetList="assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'snoozed'
                            && asset.status != 'disabled'
                            )" 
                        />
                </rounded-container>
            </div>
        </div>
        <div v-else>
            loading screen
        </div>
    </div>
</template>

<script>

import formatter from '../mixins/formatter'
import assetMixin from '../mixins/assetMixin'

import ListAssetsTable from '../components/asset/ListAssetsTable.vue';
import AssetNavbar from '../components/AssetNavbar.vue';
import RoundedContainer from '../components/style/RoundedContainer.vue';

const convictionOptions = {
    'core': '100k',
    'high conviction': 'high',
    'regular': null,
    'low conviction': 'low',
}

export default {
    name: 'Assets',
    mixins: [formatter, assetMixin],
    components: {
        ListAssetsTable,
        RoundedContainer,
        AssetNavbar,
    },
    props: ['market', 'assets'],
    data() {
        return {
            filterMarket: '',
            highConvictionAssets: [],
            lowConvictionAssets: [],
            snoozedAssets: [],
            otherAssets: [],
            convictionOptions: convictionOptions,
        }
    },
    created() {
        this.filterMarket = this.market;
    },
    watch: {
        $route(to) {
            // force content refresh when switching props
            this.filterMarket = to.params.market; 
        }
    }
};
</script>

<style>
.asset_settings {
    color: #fff;
    padding: 12px;
    background-color: #729DCB;
}
.asset_settings a {
    text-decoration: underline;
    color: #fff;
}
</style>