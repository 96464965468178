<script>
import { Line, mixins } from 'vue-chartjs'

export default {
    name: 'AltLineChart',
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: {
        chartData: {
            type: Object,
        },
        chartOptions: {
            type: Object,
        },
    },

    mounted() {
        this.renderChart(this.chartData, this.chartOptions);
    },
};
</script>
