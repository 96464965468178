import { mapState } from "vuex";

import axios from 'axios';

export default {
    data() {
        return {
            
        }
    },
    methods: {
        async fetchBucketList() {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
                axios
                    .get(this.$store.state.baseURL[this.$store.state.env] + '/api/buckets')
                    .then((response) => {
                        let bucketList = response.data
                        this.$store.dispatch('addBuckets', bucketList)
                        resolve(bucketList)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getBucketByPublicId(public_id) {
            return this.buckets.filter(bucket => bucket.public_id == public_id)[0]
        }
    },
    computed: {
        ...mapState({
            buckets: state => state.buckets.bucketList,
        }),
        bucketFormOptions() {
            let bucketOptions = []

            this.buckets.forEach(bucket => bucketOptions.push({
                text: bucket.name,
                value: bucket.public_id,
            }))

            return bucketOptions
        }
    }
}