<template>
    <div class="modal-style">
        <h2>
            <span class="mr-2">{{ assetContext.symbol }}</span>
            <span class="text-secondary">
                <span class="mr-2">${{ formatHoldings(groupContext.value) }} @{{ groupContext.open_price }}</span>
            </span>
        </h2>

        <b-form v-if="groupContext.stage != 'converted' || groupContext.stage != 'closed'" 
                acton="#" 
                @submit.prevent="saveGroup" 
                autocomplete="off" 
                class="mt-3"
            >
            <b-row>
                <b-col>
                    <div class="text-secondary mb-1">label/name</div>

                    <b-form-input
                        type="text" 
                        name="groupName" 
                        id="groupName" 
                        placeholder="Group name"
                        v-model="form.groupName"
                    ></b-form-input>
                </b-col>
                <b-col>
                    <div class="text-secondary mb-1">multiplier</div>

                    <b-form-input
                        type="text" 
                        name="multiplier" 
                        id="group-multiplier" 
                        placeholder="Multiplier"
                        v-model="form.multiplier"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row v-if="convertOptions" class="mt-3">
                <b-col>
                    <div v-if="form.bucket_public_id != groupContext.bucket_public_id" class="mb-1 text-warning">bucket</div>
                    <div v-else class="mb-1 text-secondary">bucket</div>

                    <b-form-select 
                        v-model="form.bucket_public_id" 
                        :options="bucketOptions"
                        name="bucket"
                        id="target-bucket"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Bucket --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col>
                    <div v-if="(groupContext.value / 50).toFixed() != form.noPositions" class="mb-1 text-warning">positions</div>
                    <div v-else class="mb-1">positions</div>

                    <b-form-select
                        name="starting-price" 
                        id="starting-price" 
                        :options="positionsOptions"
                        v-model="form.noPositions"
                    ></b-form-select>
                </b-col>
                <b-col>
                    <div class="mb-1">@ in {{ assetContext.currency }}</div>
                    <b-form-input
                        type="text" 
                        name="starting-price" 
                        id="starting-price" 
                        placeholder="Starting Price"
                        v-model="form.startingPrice"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <b-overlay
                        :show="saveGroupBusy"
                        rounded
                        opacity="0.1"
                        spinner-small
                        spinner-variant="light"
                        class="d-inline-block"
                    >
                        <b-button variant="primary" 
                            v-if="canSubmit" 
                            type="submit" 
                            class="mr-2"
                        >Save
                        </b-button>
                        <b-button variant="primary" 
                            v-else
                            disabled
                            type="submit" 
                            class="mr-2"
                        >Save
                        </b-button>
                    </b-overlay>

                    <b-button variant="warning" 
                        class="mr-2" 
                        v-if="!convertOptions"
                        @click.prevent="showConvertOptions"
                    >Convert...</b-button>

                    <b-button
                        class="mr-2" 
                        v-if="convertOptions"
                        @click.prevent="hideConvertOptions"
                        >Cancel conversion</b-button>
                    
                    <b-button @click.prevent="hideModal">
                        Discard
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import axios from 'axios';

import bucketMixin from '../../mixins/bucketMixin'
import formatter from '../../mixins/formatter'

export default {
    name: 'ManageGroup',
    props: [
        'assetContext', 
        'groupContext'
    ],
    mixins: [formatter, bucketMixin],
    components: {

    },
    data() {
        return {
            convertOptions: false,

            form: {
                bucket_public_id: this.groupContext.bucket_public_id,
                groupName: this.groupContext.name,
                multiplier: this.groupContext.multiplier,
                startingPrice: (this.groupContext.open_price * this.groupContext.multiplier),
                noPositions: (this.groupContext.value / 50).toFixed()
            },
            bucketOptions: [],
            positionsOptions: (() => {let opt = []; for (let i = 1; i <= (this.groupContext.value / 50).toFixed(); i++) { opt.push({ value: i, text: '$'+(i*50)+' ('+i+' pos)' }) } return opt;})(),

            saveGroupBusy: false,
        }
    },
    created() {
        this.bucketOptions = this.bucketFormOptions
    },
    methods: {
        showConvertOptions() {
            this.convertOptions = true
        },
        saveGroup() {
            if (this.canSubmit) {
                this.saveGroupBusy = true;

                let convertPayload = {}
                let action = 'edit'
                if (this.convertOptions) { 
                    action = 'convert' 

                    convertPayload = {
                        bucket_public_id: this.form.bucket_public_id,
                        no_positions: this.form.noPositions,
                        starting_price: this.form.startingPrice,
                    }
                }

                let payload = {
                    action: action,
                    name: this.form.groupName,
                    multiplier: this.form.multiplier,
                    ...convertPayload
                }
                console.log('payload', payload)     
            
                axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
                axios.patch(
                        this.$store.state.baseURL[this.$store.state.env] + '/api/group/' + this.groupContext.public_id, 
                        payload, 
                        {
                            headers: {
                                // Overwrite Axios's automatically set Content-Type
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                    .then((response) => {
                        console.log('response.data', response.data)
                        
                        if (response.data.asset) {
                            this.$store
                                .dispatch('updateAssets', [response.data.asset])
                                .then(() => this.$FModal.hide())
                        }

                        this.addGroupBusy = false; 
                    })
                    .catch((error) => {
                        console.log(error);
                        this.addGroupBusy = false; 
                    })
            }
        },
        hideConvertOptions() {
            this.convertOptions = false
        },
        hideModal() {
            this.$FModal.hide()
        }
    },
    computed: {
        canSubmit() { 
            if (this.convertOptions) {
                if (!this.form.bucket_public_id || !this.form.noPositions || !this.form.startingPrice) return false
            } else {
                if (!this.form.groupName || !this.form.multiplier) return false
            }

            if (!this.groupContext.public_id) return false

            return true
        }
    },
}
</script>
