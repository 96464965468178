<template>
    <div>


        <rounded-container v-if="json" variant="dark">
            <template v-slot:title>View JSON for {{ objectName }}</template>
            
            <div class="p-3">
                <vue-json-pretty :data="json" :deep="deep"></vue-json-pretty>
            </div>
        </rounded-container>
    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import RoundedContainer from '../components/style/RoundedContainer.vue';

export default {
    name: "JsonViewer",
    props: [
        'json',
        'objectName',
        'returnTo'
    ],
    components: {
        RoundedContainer,
        VueJsonPretty,
    },
    data() {
        return {
            deep: 2
        }
    },
};
</script>