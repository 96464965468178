<template>
    <rounded-container variant="dark">
        <template v-slot:title>last added <span class="text-secondary">{{ lastOpen.length }} positions</span></template>

        <b-table
            hover
            dark
            striped
            borderless
            small

            :fields="positionFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="lastOpen"
            v-if="lastOpen"

            current-page="1"
            per-page="10"

            class="m-0"
            thead-class="hidden_header"
        >
            <template #cell(open_time)="data">
                {{ formatTimeFromISO(data.item.open_time + 'Z') }}
            </template>
            <template #cell(ticker)="data">
                <router-link
                    class="security_symbol"
                    :to="{ name: 'asset', params: { symbol: data.item.ticker } }"
                >{{ data.item.ticker }}</router-link>
            </template>
            <template #cell(size)="data">
                ${{ data.item.size }}
            </template>
        </b-table>
    </rounded-container>
</template>

<script>

import formatter from '../../mixins/formatter'

import { mapState } from 'vuex';
import RoundedContainer from '../style/RoundedContainer.vue';

export default {
    name: 'LastAddedPositions',
    components: {
        RoundedContainer
    },
    mixins: [formatter],
    data() {
        return {
            positionFields: [
                { key: 'empty' },
                { key: 'open_time' },
                { key: 'ticker', label: "" },
                { key: 'size', label: "Amount" },
                { key: 'open_price', label: "Open" },
            ],
            sortBy: 'open_time',
            sortDesc: true,
        }
    },
    created() {
        //console.log(this.$store.state.positions);
        this.$store.dispatch("retrieveLastOpenPositions")
    },
    methods: {
        formatDate(dateObject) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            let month = months[dateObject.getMonth()];
            let day = dateObject.getDate();

            return month + ' ' + day;
        },
        retrievePosition(pid) {
            return this.positions.positionMap.get(pid)
        }
    },
    computed: {
        ...mapState({
            positions: state => state.positions
        }),
        lastOpen() {
            let rd = []
            let pids = this.positions.lastOpenPositions;
            for (let i=0; i<pids.length; i++) {
                rd.push(this.positions.positionMap.get(pids[i]))
            }
            return rd;
        }
    }
}
</script>

<style>
.hide_header {
    display: none;
}
</style>