<template>
    <div>
        <h1 class="mx-3 mt-3">Binance</h1>

        <rounded-container variant="dark">
            <template v-slot:title>
                <span v-if="binance.accountOffset > 0"><strong>${{ binance.accountOffset }}</strong> offset, <strong>${{ binance.cashAvailable }}</strong> balance </span>
                <span v-else-if="binance.accountOffset < 0"><strong>-${{ Math.abs(binance.accountOffset) }}</strong> offset, <strong>${{ binance.cashAvailable }}</strong> balance </span>
                <span v-else>Binance </span>
            </template>

            <b-container class="p-0" v-if="binance.balances.length">
                <b-row class="px-3 py-2 text-secondary">
                    <b-col cols=4><strong>asset</strong></b-col>
                    <b-col><strong>offset</strong></b-col>
                    <b-col><strong>Binance</strong></b-col>
                    <b-col><strong>Warren</strong></b-col>
                </b-row>

                <b-row v-for="(balance, rowIndex) in binance.balances" :key="balance.symbol" :class="[rowClass(rowIndex), canLongClass(balance)]" class="px-3 py-2 text-secondary">
                    <b-col class="no-bold" cols=4>
                        <span v-if="'asset' in balance">
                            <asset-symbol :asset="balance.asset" />
                        </span>
                        <span v-else>{{ balance.symbol }}</span>
                    </b-col>

                    <b-col>
                        <span class="text-success" v-if="balance.surplus_value > 0">
                            ${{ balance.surplus_value }}
                        </span>
                        <span class="text-danger" v-else-if="balance.surplus_value">
                            -${{ Math.abs(balance.surplus_value) }}
                        </span>
                        <span v-else>
                        </span>
                    </b-col>

                    <b-col class="locked-amount">
                        {{ formatNumber(balance.binance_free) }}
                    </b-col>

                    <b-col class="locked-amount">
                        <span v-if="balance.warren_locked">
                            {{ formatNumber(balance.warren_locked) }}
                        </span>
                        <span v-else-if="'asset' in balance">
                            0
                        </span>
                        <span v-else>
                            N/A
                        </span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container v-else class="text-center text-secondary p-5">
                Loading balances...
            </b-container>
        </rounded-container>
        
        <rounded-container variant="dark">
            <template v-slot:title>
                Binance orders <span v-if="binance.openOrders.length" class="text-secondary">{{ binance.openOrders.length }} open</span>
            </template>

            <b-container v-if="binance.openOrders.length" class="p-0">
                <b-row v-for="(order, rowIndex) in binance.openOrders" :key="order.orderId" :class="rowClass(rowIndex)" class="px-3 py-2">
                    <b-col>
                        <span v-if="order.side == 'SELL'" class="text-danger">{{ order.symbol }}</span>
                        <span v-if="order.side == 'BUY'" class="text-success">{{ order.symbol }}</span>
                    </b-col>
                    <b-col>
                        <span v-if="(order.price * order.origQty) > 50.5">${{ formatPrice(order.price * order.origQty) }}</span>
                        <span v-else class="text-secondary">${{ formatPrice(order.price * order.origQty) }}</span>
                    </b-col>
                    <b-col>${{ formatPrice(parseFloat(order.price)) }}</b-col>
                    <b-col>{{ formatTime(new Date(order.updateTime)) }}</b-col>
                </b-row>
            </b-container>
            <b-container v-else class="text-center text-secondary p-5">
                No open orders.
            </b-container>
        </rounded-container>
    </div>
</template>
<script>

import axios from 'axios';

import formatter from '../mixins/formatter';
import RoundedContainer from '../components/style/RoundedContainer.vue';
import AssetSymbol from '../components/style/AssetSymbol.vue';

export default {
    name: 'Binance',
    mixins: [formatter],
    components: { RoundedContainer, AssetSymbol },
    data() {
        return {
            binance: {
                binanceTime: null,
                updateTime: null,
                cashAvailable: null,
                accountOffset: null,
                balances: [],
                openOrders: [],
                gotData: false
            }
        }
    },
    created() {
        this.retrieveBinanceClient()
    },
    methods: {
        retrieveBinanceClient() {
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/binance-client')
                .then((response) => {
                    this.binance.binanceTime = response.data.binance_time;
                    this.binance.updateTime = response.data.update_time;
                    this.binance.cashAvailable = response.data.cash_available;
                    this.binance.accountOffset = response.data.account_offset;
                    this.binance.openOrders = response.data.open_orders.sort((a, b) => (a.updateTime < b.updateTime) ? 1 : -1);
                    this.binance.balances = response.data.balances.sort((a, b) => (Math.abs(a.surplus_value) < Math.abs(b.surplus_value)) ? 1 : -1);
                    
                    this.binance.gotData = true;
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
        rowClass(rowIndex) {
            if (rowIndex % 2) return 'oddRow';

            return 'evenRow'
        },
        canLongClass(balance) {
            if ('asset' in balance && 'canLong' in balance.asset.meta && balance.asset.meta.canLong) {
                if (balance.surplus_value > 50) return 'highlight-row';
            }

            return '';
        }
    }
}
</script>
<style scoped>
.highlight-row {
    font-weight: bold;
}
.highlight-row .locked-amount {
    color: #fff;
}
.no-bold {
    font-weight: normal;
}
</style>