<script>
import { Bar } from 'vue-chartjs'

export default {
    name: 'BuysClosedChart',
    extends: Bar,
    props: ['chartData'],

    data() {
        return {
            options: {
                title: {
                    display: true,
                    text: 'New and closed positions - 30 days'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            display: false
                        },
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                },
            }
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
};
</script>
