<template>
    <span class="monospaced text-right">
        <b-badge v-if="distance.value > 8" variant="success">{{ distance.formatted }}</b-badge>
        <b-badge v-else-if="distance.value > 2" variant="secondary">{{ distance.formatted }}</b-badge>
        <b-badge v-else-if="distance.value >= 0" variant="warning">{{ distance.formatted }}</b-badge>
        <b-badge v-else variant="danger">{{ distance.formatted }}</b-badge>
    </span>    
</template>

<script>

export default {
    name: 'PriceDistance',
    props: ['openPrice', 'currentPrice'],
    data() {
        return {

        }
    },
    methods: {
    },
    computed: {
        distance() {
            let distance = Math.round((this.currentPrice / this.openPrice - 1) * 1000) / 10;

            return {
                value: distance,
                formatted: (distance > 0 ? '+'+distance: ''+distance) +'%'
            }
        },
    }
}

</script>


<style>
.monospaced { 
    font-family: var(--font-family-monospace);
}
</style>