<template>
    <div>
        <b-form acton="#" @submit.prevent="onSubmit" autocomplete="off" class="py-3">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <b-form-select 
                            v-model="form.symbol" 
                            :options="assets.assetSymbolList"
                            name="symbol"
                            id="symbol"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Select Asset --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col>
                        <b-form-select 
                            v-model="form.type" 
                            :options="boostType"
                            name="type"
                            id="type"
                            class="text-center"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Period --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col class="text-right">
                        <div v-if="buttonLoadingState">
                            <b-button type="submit" variant="primary"><b-spinner small type="grow"></b-spinner></b-button>
                        </div>
                        <div v-else>
                            <b-button type="submit" variant="primary">Boost {{ labelOrSymbol }}</b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-form>

    </div>
</template>

<script>

import axios from 'axios';
import { mapState } from "vuex";

export default {
    name: 'Boosts',
    props: ['requestUpdate'],
    data() {
        return {
            form: {
                symbol: null,
                type: null,
            },
            boostType: ['7d', '30d', '90d', '1y'],
            buttonLoadingState: false,
        }
    },
    methods: {
        onSubmit() {
            this.buttonLoadingState = true;

            if (this.form.symbol && this.form.type) {
                axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
                axios.post(
                        this.$store.state.baseURL[this.$store.state.env] + '/api/boost/add',
                        {
                            symbol: this.form.symbol,
                            boost_type: this.form.type,
                        }
                    )
                    .then((response) => {
                        if (response.data.boost) {
                            this.form = {
                                symbol: null,
                                type: null,
                            }
                            this.popMessage('Asset added to database', 'success');
                            this.buttonLoadingState = false;
                            
                            this.$emit('update:requestUpdate', true);  
                        }
                        else {
                            this.popMessage('API error, check view /boost/add', 'danger');
                            this.buttonLoadingState = false;
                        }     
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            this.popMessage(error.response.data, 'danger');
                        }
                        else {
                            this.popMessage('API error', 'danger');    
                        }
                        this.buttonLoadingState = false;

                        // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                        if (error.request.status == 401) {
                            localStorage.removeItem('access_token');
                            this.$store.context.commit('destroyAccessToken');
                        }
                    });
            }
            else {
                this.popMessage('Invalid selection', 'danger');
                this.buttonLoadingState = false;
            }
        },
        popMessage(message, variant = 'info') {
            this.$bvToast.toast(message, {
                title: `:(`,
                toaster: 'b-toaster-bottom-center',
                solid: true,
                appendToast: true,
                variant: variant,
            })
        }
    },
    computed: {
        ...mapState({
            assets: state => state.assets,
        }),
        labelOrSymbol() {
            if (this.form.symbol) return this.form.symbol;
            return 'asset';
        }
    },
}

</script>

<style>
</style>