import Vue from "vue";
import VueRouter from "vue-router";

import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Asset from '../views/Asset.vue';
import Stats from '../views/Stats.vue';
import Portfolio from '../views/Portfolio.vue';
import Buckets from '../views/Buckets.vue'
import Binance from '../views/Binance.vue'
import Profile from '../views/Profile.vue';
import Boosts from '../views/Boosts.vue';
import Logout from '../views/Logout.vue';
import Assets from '../views/Assets.vue';
import StalePositions from '../views/StalePositions.vue';
import Groups from '../views/Groups.vue';
import JsonViwer from '../views/JsonViewer.vue';


//import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/stats',
        name: 'stats',
        component: Stats,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: Portfolio,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/buckets',
        name: 'buckets',
        component: Buckets,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/binance',
        name: 'binance',
        component: Binance,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/asset/:symbol',
        name: 'asset',
        component: Asset,
        props: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/view-json',
        name: 'jsonViewer',
        component: JsonViwer,
        props: route => ({...route.params}),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/boosts/:market',
        name: 'boosts',
        component: Boosts,
        props: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/groups/:market',
        name: 'groups',
        component: Groups,
        props: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/assets/:market',
        name: 'assets',
        component: Assets,
        props: true,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/positions/stale',
        name: 'stalePositions',
        component: StalePositions,
        meta: {
            requiresAuth: true,
        }
    }
    //{ path: '*', component: NotFoundComponent }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (/*to, from*/) {
    //console.log('router from/to', to, from);
    return { x: 0, y:0 }
  }
});

// https://router.vuejs.org/guide/advanced/meta.html
/*
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters.loggedIn) {
        next({
          name: 'login'
        })
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
})
*/
export default router;
