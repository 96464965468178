export default {
    data() {
        return {
            monthName: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        }
    },
    methods: {
        getDistance(open_price, current_price) {
            let distance = Math.round((current_price / open_price - 1) * 1000) / 10;
            
            return {
                value: distance,
                formatted: (distance > 0 ? '+'+distance: ''+distance) +'%'
            }
        },
        formatNumber(value) {
            if (value == 0) return 0

            let unsigned_value = Math.abs(value)
            let return_value = 0

            if (unsigned_value >= 1000) return_value = unsigned_value.toFixed();
            else if (unsigned_value >= 100) return_value = unsigned_value.toFixed(1);
            else if (unsigned_value >= 10) return_value = unsigned_value.toFixed(2);
            else if (unsigned_value >= 1) return_value = unsigned_value.toFixed(3);
            else return_value = unsigned_value.toFixed(4);

            if (value < 0) return_value = 0 - return_value

            return return_value
        },
        formatPrice(price) {
            var no = new Intl.NumberFormat('en-US');
            return no.format(this.formatNumber(price))
        },
        formatHoldings(price) {
            if (!price) return 0;

            var no = new Intl.NumberFormat('en-US');

            if (price > 1000) {
                return (price / 1000).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] + 'K'
            }

            if (price < -1000) {
                return (0 - (Math.abs(price) / 1000).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]) + 'K'
            } else if (price < 0) {
                return 0 - this.formatNumber(Math.abs(price))
            }

            
            return no.format(this.formatNumber(price))
        },
        formatTime(dateObject) {
			const now = new Date();
            const daysDiff = (now - dateObject) / (1000 * 3600 * 24);

            if (daysDiff < 0.5 || dateObject.toISOString().split('T')[0] === now.toISOString().split('T')[0]) {
                let time = dateObject.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                return time.toLowerCase();
            } else {
                let dateString = this.monthName[dateObject.getMonth()] + ' ' + dateObject.getDate()
                if (now.getFullYear() === dateObject.getFullYear()) {
                    return dateString
                }
                else {
                    return dateString + ', ' + dateObject.getFullYear()
                }
            }

        },
        dateFromISO(timeString) {
            if (timeString.includes('+')) timeString = timeString.split('+')[0]+'Z';
            if (!timeString.includes('Z')) timeString = timeString+'Z';

            return new Date(timeString);

        },
        formatTimeFromISO(timeString) {
            return this.formatTime(this.dateFromISO(timeString));
        },
        rowClass(rowIndex) {
            if (rowIndex % 2) return 'oddRow';

            return 'evenRow'
        },
        apiErrorToast(error) {
            this.$bvToast.toast(error.response.data , {
              title: 'API Error' + ((error.response.status) ? ` (${error.response.status})`: ''),
              autoHideDelay: 5000,
              toaster: 'b-toaster-top-center',
              variant: 'danger',
            });
        },
        apiSuccessToast(response, toastTitle = null) {
            if (!response.data.message) return;
            
            this.$bvToast.toast(response.data.message , {
                title: ((toastTitle) ? toastTitle : 'API request'),
                autoHideDelay: 5000,
                toaster: 'b-toaster-top-center',
                variant: 'success',
            });
        }
    }
}