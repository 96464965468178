//import { set, get } from 'idb-keyval';

const state = () => ({
    assetMap: new Map(),
    assetMapByPublicId: new Map(),
    assetSymbolList: [],
    assetMapper: {},
    
    // fmm, am ajuns si la versiunea asta ca sa nu am un computed getter 
    // si sa mearga totul reactiv
    assetList: [],
})


const getters = {
    getAssetBySymbol: (state) => (symbol) => {
        return state.assetMap.get(symbol);
    },
    getAssetByPublicId: (state) => (assetPublicId) => {
        return state.assetMapByPublicId.get(assetPublicId);
    },
    getAssetMapper: (state) => {
        return state.assetMapper;
    },
}

const actions = {
    resetAssets(context) {
        context.commit('resetAssetList');
    },
    updateAssets(context, assetList) {
        for (let i = 0; i < assetList.length; i++) {
            context.commit('updateAsset', assetList[i]);
        }
    },
    addViewedAsset(context, asset) {
        if (asset) {
            context.commit('addViewedAssetToState', asset);
        }
        else {
            console.log('store:assets', 'Asset adding error')
        }
    }
}

const mutations = {
    resetAssetList(state) {
        state.assetList = []
    },
    updateAsset(state, asset) {
        state.assetMap.set(asset.symbol, asset); // old way of storing/retrieving asset data
        state.assetMapByPublicId.set(asset.public_id, asset); // preferred way of storing/retrieving asset data
        state.assetMapper[asset.symbol] = asset; // :shrug:

        // and I seem to have a basic list of objects
        let assetUpdated = false;
        for (let i = 0; i < state.assetList.length; i++) {
            if (state.assetList[i].symbol == asset.symbol) {
                state.assetList.splice(i, 1, asset);
                /*get('assets').then((assetList) => {
                    assetList.filter((eachAsset, index) => {
                        if (eachAsset.symbol == asset.symbol) assetList[index] = asset;
                    })
                    set('assets', assetList);
                })*/
                assetUpdated = true;
            }
        }
        
        if (!assetUpdated) state.assetList.push(asset);

        if (!state.assetSymbolList.includes(asset.symbol)) {
            state.assetSymbolList.push(asset.symbol);
        }
    },
    sortAssetSymbolList(state) {
        state.assetSymbolList.sort()
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}