import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import './registerServiceWorker'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import FullpageModal from 'vue-fullpage-modal'

Vue.use(FullpageModal)

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    computed: {},
    render: (h) => h(App),
}).$mount('#app');
