<template>
    <div>
        <b-row v-for="(position, rowIndex) in positionsFirstHalf" :key="position.pid" :class="rowClass(rowIndex)" class="small">
            <b-col class="formatPrice">{{ formatTimeFromISO(position.open_time) }}</b-col>
            
            <b-col class="formatPrice text-success" v-if="getIndicator(position) >= 10">+{{ getIndicator(position) }}%</b-col>
            <b-col class="formatPrice text-info" v-else-if="getIndicator(position) >= 9">+{{ getIndicator(position) }}%</b-col>
            <b-col class="formatPrice text-white" v-else>+{{ getIndicator(position) }}%</b-col>

            <b-col class="formatPrice" cols="3">{{ formatPrice(position.open_price) }} &#x21E2;</b-col>
            <b-col class="formatPrice" cols="2">{{ formatPrice(position.close_price) }}</b-col>
        </b-row>

        <b-row v-if="positionsSecondHalf.length" class="text-center text-secondary">
            <b-col>- - -</b-col>
        </b-row>

        <b-row v-for="(position, rowIndex) in positionsSecondHalf" :key="position.pid" :class="rowClass(rowIndex+1)" class="small">
            <b-col class="formatPrice">{{ formatTimeFromISO(position.open_time) }}</b-col>
            
            <b-col class="formatPrice text-success" v-if="getIndicator(position) >= 10">+{{ getIndicator(position) }}%</b-col>
            <b-col class="formatPrice text-info" v-else-if="getIndicator(position) >= 9">+{{ getIndicator(position) }}%</b-col>
            <b-col class="formatPrice text-white" v-else>+{{ getIndicator(position) }}%</b-col>

            <b-col class="formatPrice" cols="3">{{ formatPrice(position.open_price) }} &#x21E2;</b-col>
            <b-col class="formatPrice" cols="2">{{ formatPrice(position.close_price) }}</b-col>
        </b-row>
    </div>
</template>

<script>
import formatter from "@/mixins/formatter";

export default {
    name: "CompactPositionList",
    props: ['positionList', 'refPrice'],
    mixins: [formatter],
    data() {
        return {
        }
    },
    methods: {
        getIndicator(position) {
            if (this.refPrice) return ((this.refPrice / position.open_price) * 100 - 100).toFixed(2);
            return position.indicator;
        },
        rowClass(rowIndex) {
            if (rowIndex % 2) return 'text-secondary';
            return 'text-light';
        },
    },
    computed: {
        positionsFirstHalf() {
            if (this.positionList.length > 11) return this.positionList.slice(0, 5);
            return this.positionList;
        },
        positionsSecondHalf() {
            if (this.positionList.length > 11) return this.positionList.slice(-5);
            return [];
        }
    }
}
</script>
<style scoped>

</style>
