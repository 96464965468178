<template>
    <div>
        <h1 class="mx-3 mt-3">Buckets</h1>

        <rounded-container variant="dark">
            <template v-slot:title>
                Binance that can long
            </template>

            <b-container class="p-0 pb-2">
                <b-row class="custom-table-head text-secondary px-3 py-2" no-gutters>
                    <b-col cols=4>asset</b-col>
                    <b-col>offset</b-col>
                    <b-col>trading</b-col>
                    <b-col>long</b-col>
                </b-row>

                <b-row 
                    class="px-3 py-2" 
                    :class="rowClass(rowIndex)" 
                    v-for="(asset, rowIndex) in JSON.parse(JSON.stringify(canLongAssets))
                                    .filter(asset => asset.data_source == 'binance')
                                    .sort((a, b) => (Math.abs(a.offset) < Math.abs(b.offset)) ? 1 : -1)
                                    "
                    :key="asset.symbol"
                    no-gutters
                    >

                    <b-col cols=4><asset-symbol :asset="asset" /></b-col>
                    <b-col class="format-price">
                        <span class="text-success" v-if="(sumBalances(asset.balances, 'trading') - asset.locked_shares) * asset.price > 0">&nbsp;${{ formatHoldings((sumBalances(asset.balances, 'trading') - asset.locked_shares) * asset.price) }}</span>
                        <span class="text-danger" v-else>-${{ formatHoldings(Math.abs(sumBalances(asset.balances, 'trading') - asset.locked_shares) * asset.price) }}</span>
                    </b-col>
                    <b-col class="text-secondary format-price">
                        ${{ formatHoldings(sumBalances(asset.balances, 'trading') * asset.price) }}
                    </b-col>
                    <b-col class="text-secondary format-price">
                        ${{ formatHoldings(sumBalances(asset.balances, 'long') * asset.price) }}
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col class="text-center pt-2"><b-button :to="{ name: 'binance' } ">view Binance</b-button></b-col>
                </b-row>
            </b-container>
        </rounded-container>

        <rounded-container variant="dark">
            <template v-slot:title>
                Stocks that can long
            </template>

            <b-container class="p-0 pb-2">
                <b-row class="custom-table-head text-secondary px-3 py-2" no-gutters>
                    <b-col cols=4>asset</b-col>
                    <b-col>offset</b-col>
                    <b-col>trading</b-col>
                    <b-col>long</b-col>
                </b-row>

                <b-row 
                    class="px-3 py-2" 
                    v-for="asset in JSON.parse(JSON.stringify(canLongAssets))
                                    .filter(asset => asset.market == 'stock')
                                    .sort((a, b) => (Math.abs(a.offset) < Math.abs(b.offset)) ? 1 : -1)
                                    "    
                    :class="assetCanLongClass(asset)" 
                    :key="asset.symbol"
                    no-gutters
                    >

                    <b-col cols=4><asset-symbol :asset="asset" /></b-col>
                    <b-col class="format-price">
                        <span class="text-success" v-if="(sumBalances(asset.balances, 'trading') - asset.locked_shares) * asset.price > 0">&nbsp;${{ formatHoldings((sumBalances(asset.balances, 'trading') - asset.locked_shares) * asset.price) }}</span>
                        <span class="text-danger" v-else>-${{ formatHoldings(Math.abs(sumBalances(asset.balances, 'trading') - asset.locked_shares) * asset.price) }}</span>
                    </b-col>
                    <b-col class="text-secondary format-price">
                        ${{ formatHoldings(sumBalances(asset.balances, 'trading') * asset.price) }}
                    </b-col>
                    <b-col class="text-secondary format-price">
                        ${{ formatHoldings(sumBalances(asset.balances, 'long') * asset.price) }}
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col class="text-center pt-2"><b-button :to="{ name: 'binance' } ">view Binance</b-button></b-col>
                </b-row>
            </b-container>
        </rounded-container>
    </div>    
</template>

<script>

import formatter from '../mixins/formatter';
import RoundedContainer from '../components/style/RoundedContainer.vue';
import AssetSymbol from '../components/style/AssetSymbol.vue';

export default {
    name: 'Buckets',
    mixins: [formatter],
    components: { RoundedContainer, AssetSymbol },
    data() {
        return {

        }
    },
    methods: {
        assetCanLongClass(asset) {
            if ('canLong' in asset.meta && asset.meta.canLong) return 'asset-can-long'
        },
        rowClass(rowIndex) {
            if (rowIndex % 2) return 'oddRow';

            return 'evenRow'
        },
        sumBalances(balances, trading_or_long) {
            let sum = 0 
            for (let i=0; i<balances.length; i++) {
                if (balances[i].bucket.type == trading_or_long) {
                    sum += balances[i].shares
                } 
            }

            return sum
        }
    },
    computed: {
        canLongAssets() {
            return this.$store.state.assets.assetList.filter(asset => (asset.meta && 'canLong' in asset.meta && asset.meta.canLong));
        },
    }
}

</script>

<style scoped>
.asset-can-long {
    font-weight: bold;
}
</style>
