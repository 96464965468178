const state = () => ({
    groups: [],                     // unstructured list of all groups
    groupsMap: new Map(),           // groupId: group 
    groupIdsBySymbol: new Map(),    // assetSymbol: groupId
})

const getters = {
    getBySymbol(state, symbol) {
        return state.groupIdsBySymbol.get(symbol)
    }
}

const actions = {
    addGroup(context, group) {
        context.commit('checkAndRegisterGroup', group);
    },
    addGroupList(context, groupList) {
        for (let i = 0; i < groupList.length; i++) {
            context.commit('checkAndRegisterGroup', groupList[i]);
        }
    },
}

const mutations = {
    checkAndRegisterGroup(state, group) {
        if (!state.groups.includes(group)) {
            state.groups.push(group);
            state.groupsMap.set(group.public_id, group);
            state.groupIdsBySymbol.set(group.asset_symbol, group);
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}