<template>
    <div>
        <experiments />
    </div>
</template>

<script>

import Experiments from '../components/Experiments.vue';

export default {
    name: "Profile",
    components: {
        Experiments
    },
};

</script>

<style>

</style>